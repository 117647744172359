import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {DialogActions} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const PathHistoryDialog = ({open, onClose, urls}) => {
    if (!open) {
        return null
    }
    return (
        <Dialog open={open} onClose={onClose}
                maxWidth="lg"
                fullWidth>

            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {"User Last Visited Pages"}
                <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{display: 'flex', flexWrap: 'wrap', overflowX: 'auto'}}>
                    {urls.map((url, index) => (
                        <Box key={index} sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="body1">
                                <a>{url}</a>
                            </Typography>
                            <div style={{marginRight: '5px'}}>
                                {index !== urls.length - 1 && (
                                    <IconButton edge="end" aria-label="next">
                                        <ArrowRightAltIcon/>
                                    </IconButton>
                                )}
                            </div>
                        </Box>
                    ))}
                </Box>
            </DialogContent>

        </Dialog>
    );
}

export default PathHistoryDialog;
