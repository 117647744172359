import React from "react";
import { Chip } from "@mui/material";
import "../style.css";
const ChipList = ({ list, onDelete, highlightList, onClick }) => {
  return list?.map((tag, i) => {
    let cond = highlightList?.includes(tag);
    return (
      <Chip
        onDelete={
          onDelete
            ? () => {
                onDelete(i);
              }
            : null
        }
        key={i}
        className={cond ? "tagChipsOutlined cardTags" : "tagChips cardTags"}
        label={tag}
        color="primary"
        variant={cond ? "outlined" : "filled"}
        onClick={onClick ? () => onClick(tag) : null}
      />
    );
  });
};

export default ChipList;
