import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { CheckCircle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Auth } from "aws-amplify";

const sendSession = async () => {
  try {
    // Retrieve the current session
    const session = await Auth.currentSession();
    console.log('send to extesnion')
    console.log("Session after redirect:", session);

    const accessToken = session.getAccessToken().getJwtToken();
    console.log('Access Token:', accessToken);

    console.log('refresh')
    let refreshToken = session.getRefreshToken().getToken()
    console.log(refreshToken)
    sendTokensToExtension(accessToken,refreshToken)

    // Now, make the API call to get the user's profile
   
  } catch (error) {
    console.error("Error retrieving session after redirect:", error);
  } finally {
    console.log('sd')
  }
};
const sendTokensToExtension =(accessToken, refreshToken) =>{
  window.postMessage({
    action: "sendTokens",
    accessToken: accessToken,
    refreshToken: refreshToken,
  }, "*");
}
const ExtensionLoginSuccess = () => {
  const token = useSelector((state) => state.token);

  useEffect(() => {
    localStorage.removeItem('is_extension_login')
    // sendSession()
  }, []);

 
  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center" 
      height="100vh"
      textAlign="center"
      color="success.main"
    >
      <CheckCircle style={{ fontSize: 60 }} />
      <Typography variant="h4" gutterBottom>
        Login Successful!
      </Typography>
      <Typography variant="body1">
        You can now use the Datarate extension.
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Please note: Sometimes, a page refresh is required to activate the extension.
      </Typography>
    </Box>
  );
};

export default ExtensionLoginSuccess;
