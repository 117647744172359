import React, {useState, useEffect} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import {
    Button,
    Grid,
    TextField,
    Autocomplete,
    IconButton,
    FormControl,
    InputLabel,
    Chip,
    Input,
    InputAdornment,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import ChatIcon from "@mui/icons-material/Chat";
import SendIcon from "@mui/icons-material/Send";
import {API_URL} from "../../consts";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import axios from "axios";
import ChipList from "../common/ChipList";
import {popupTimer} from "../../consts";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
};

const RatingObject = ({isFeedback, id, open, handleClose, onRateSuccessfully}) => {
    const dispatch = useDispatch();
    const [openComment, setOpenComment] = useState(true);
    const [ratingObject, setRatingObject] = useState(0);
    const [comment, setComment] = useState("");
    const [commenterr, setCommenterr] = useState(false);
    const [tags, setTags] = useState([]);

    const clearForm = () => {
        handleClose();
        setOpenComment(false);
        setComment("");
        setRatingObject(0);
        setCommenterr(false);
        setTags([]);
    };
    const addRate = () => {
        const data = {
            id: id,
            tags: tags,
            stars: parseInt(ratingObject),
            comment: comment,
        };
        const url = API_URL + `/rates`;
        axios
            .post(url, data)
            .then((res) => {
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: "Rating Added Successfully",
                        type: "success",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
                onRateSuccessfully(id);
                clearForm();
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "You must Login to Perform this Action",
                            type: "error",
                        },
                    });
                    setTimeout(() => {
                        dispatch({
                            type: "SET_POPUP",
                            payload: {
                                text: "",
                                type: "",
                            },
                        });
                    }, popupTimer);
                    clearForm();
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });
    };


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
        >
            <Fade in={open}>
                <Box sx={style} className="signup-modal">
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        <CloseIcon
                            className="cross-icon"
                            onClick={() => {
                                clearForm()
                            }}
                        />
                        <div className="card-heading mb-81"></div>
                    </Typography>
                    <Box id="transition-modal-description">
                        <Typography
                            id="transition-modal-title"
                            variant="h2"
                            component="h2"
                            sx={{fontSize: "20px", textAlign: "center", fontWeight: "700"}}
                        >
                            {!isFeedback && <div> Rate this Object</div>}
                            {isFeedback && <div> Replay</div>}

                        </Typography>
                        <Box sx={{textAlign: "center", marginTop: "22px"}}>
                            <Rating
                                style={{
                                    color: "rgba(255, 204, 72, 1)",
                                    marginTop: "10px",
                                    background: "#F8F8F8",
                                    padding: "8px 24px",
                                    borderRadius: "25px",
                                }}
                                name="hover-feedback"
                                value={ratingObject}
                                onChange={(e) => setRatingObject(e.target.value)}
                                emptyIcon={
                                    <StarIcon style={{opacity: 0.55}} fontSize="inherit"/>
                                }
                            />
                        </Box>

                        {!isFeedback && <Autocomplete
                            multiple
                            freeSolo
                            id="tags-outlined"
                            options={[]}
                            defaultValue={[]}
                            value={tags}
                            clearOnBlur={true}
                            onChange={(e, newval, reason) => {
                                setTags(newval);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder={"Add Keywords"}
                                    sx={{marginTop: "22px", marginBottom: "10px"}}
                                    onBlur={(e) => {
                                        if (e.target.value !== "") {
                                            setTags(tags.concat(e.target.value));
                                        }
                                    }}
                                />
                            )}
                        />
                        }
                        {!isFeedback && <Typography
                            paragraph
                            sx={{fontSize: "12px"}}
                            className="textMuted"
                        >
                            Each Tag must contain alteast Two Characters.
                        </Typography>
                        }


                        <Grid item xs={12} style={{paddingTop: "10px !important"}}>
                            <FormControl
                                error={commenterr}
                                helperText={commenterr ? "Required" : ""}
                                fullWidth
                                variant="standard"
                            >
                                <InputLabel htmlFor="standard-adornment-password">
                                    Comment
                                </InputLabel>
                                <Input
                                    className="loginTextfields"
                                    value={comment}
                                    fullWidth
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid container sx={{margin: "0 auto"}}>
                            <Grid item sx={{margin: "0 auto"}}>
                                <Button
                                    className="connectButton"
                                    variant="contained"
                                    disabled={ratingObject === 0 || tags.some(tag => tag.length < 2)}
                                    onClick={addRate}
                                >
                                    SEND
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default RatingObject;
