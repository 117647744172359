import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import BusinessAccountMainPageContent from "./BusinessAccountMainPageContent";
import BusinessForm from "./BusinessForm";
import axios from "axios";
import { API_URL } from "../../consts";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { popupTimer } from "../../consts";
import { useDispatch } from "react-redux";

const BusinessAccount = () => {
  const dispatch = useDispatch();
  const authorized = useSelector(({ authorized }) => authorized);
  const showBusinessForm = useSelector(
    ({ showBusinessForm }) => showBusinessForm
  );
  const [formOpen, setFormOpen] = useState('');
  const [data, setData] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    //get Call will be here
    getRatesRequest();
  }, []);

  useEffect(() => {
      setTimeout(()=>{
        setFormOpen(authorized || showBusinessForm);
      },1000)
  }, [authorized, showBusinessForm]);
  const getRatesRequest = () => {
    if (authorized) {
      let url = API_URL + `/tenants/my-tenant`;
      axios
        .get(url)
        .then((res) => {
          setData(res.data.tenant);
          if (res.data.tenant.plan) {
            setFormOpen(true);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            dispatch({
              type: "SET_UNAUTHORIZED",
            });
            return;
          }
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: err.response.data.error
                ? err.response.data.error
                : "Something Went Wrong. Please Try Again Later",
              type: "error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: "SET_POPUP",
              payload: {
                text: "",
                type: "",
              },
            });
          }, popupTimer);
        });
    }
  };

  const openBusinessForm = () => {
    if (!authorized) {
      navigate("/login?backurl=business");
    }
    setFormOpen(true);
  };
  return (
    <Grid container spacing={2} style={{ paddingTop: "34px" }}>
      <Grid item xs={12} sm={8} md={8} lg={8}>
        <h2>My Websites</h2>
        {formOpen === false ? (
          <BusinessAccountMainPageContent openBusinessForm={openBusinessForm} />
        ) : (formOpen === true ? (
          <BusinessForm data={data} />
        ) : (
          null
        )
        )
        }
      </Grid>
      {/* <Grid item xs={4}></Grid> */}
    </Grid>
  );
};

export default BusinessAccount;
