import {Button} from "@mui/material";
import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend, ArcElement,
} from 'chart.js';
import {Line, Doughnut} from 'react-chartjs-2';

const BusinessAccountMainPageContent = ({openBusinessForm}) => {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ArcElement,
        Tooltip,
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false
                // text: 'Chart.js Line Chart',
            },
        },
    };

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const data_don = {
        labels: ['Fun', 'Stay', 'Story', 'Blog', 'how to cook'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    const data = {
        labels,
        datasets: [
            {
                label: 'History',
                data: labels.map(() => Math.floor(Math.random() * 200)),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    return (
        <>
            {/*<div className="subHeader bussinessHeader">Connect your website to Data Rate</div>*/}
            <Line options={options} data={data}/>;
            <h3>Top of the week</h3>
            <div style={{width:'400px'}}>
                <Doughnut data={data_don}/>
            </div>
        </>
    );
};

export default BusinessAccountMainPageContent;
