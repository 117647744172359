import ChatIcon from "@mui/icons-material/Chat";
import Done from "@mui/icons-material/Done";
import StarIcon from "@mui/icons-material/Star";
import { Avatar, Button, Chip, Divider, Grid, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Link from "@mui/material/Link";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import ChipList from "../common/ChipList";
import CommentItem from "../common/CommentItem";
import StatisticsDialog from "../common/StatisticsDialog";
import RatingObject from "../RatingObject/RatingObject";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useEffect } from "react";
import axios from "axios";
import { API_URL, popupTimer } from "../../consts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import QrCodeIcon from "@mui/icons-material/QrCode";
import DeleteIcon from '@mui/icons-material/Delete';
import TimelineIcon from '@mui/icons-material/Timeline';
import AddToSpace from "./AddToSpace";
import PathHistoryDialog from "../common/PathHistoryDialog";
import MenuIcon from "@mui/icons-material/Menu";
import Description from "./Description";
import HomeCardDialog from "./HomeCardDialog";
import PublisherComment from "./PublisherComment"
const HomeCardItem = ({
    isFeedback,
    isBookmark,
    displayRemoveFromSpace,
    data,
    searhTags,
    applyMargin,
    onRateSuccessfully,
    displayStars,
    displayStats,
    onRemoveFromSpaceClick,
    showRemove,
    onRemove
}) => {
    const navigate = useNavigate();
    const [showComments, setShowComments] = useState(false);
    const [comments, setComments] = useState([]);
    const [showRateModal, setShowRateModal] = useState(false);
    const [ratingsValue, setRatingsValue] = useState(0);
    const [id, setId] = useState("");
    const [hideMore, setHideMore] = useState(true);
    const [openStatsModal, setOpenStatsModal] = useState(false);
    const [rateId, setRateId] = React.useState(null);
    const [openSpaceModal, setOpenSpaceModal] = useState(false);
    // For Dropdown Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [pathDialogOpen, setPathDialog] = React.useState(false);
    const [openedItemData, setDetailedDataDialog] = React.useState(null);
    const authorized = useSelector((state) => state.authorized);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleLike = (v, i) => {
        setComments([
            ...comments.map((m, index) => {
                if (index === i) return { ...m, liked: m.liked + (v ? +1 : -1) };
                return m;
            }),
        ]);
    };
    const handleSpaceModalClose = () => {
        setOpenSpaceModal(false);
    };
    const addComment = (i) => {
        setComments([
            ...comments.map((m, index) => {
                if (index === i) return { ...m, comments: m.comments + 1 };
                return m;
            }),
        ]);
    };

    const addToBookMarks = () => {
        let url = API_URL + `/bookmarks`;
        if (authorized) {
            axios
                .post(
                    url,
                    { rated_object_id: data.id }
              
                )
                .then((res) => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "Successfully added to your bookmarks",
                            type: "success",
                        },
                    });
                    setTimeout(() => {
                        dispatch({
                            type: "SET_POPUP",
                            payload: {
                                text: "",
                                type: "",
                            },
                        });
                    }, popupTimer);
                })
                .catch((err) => {
                    if (err.response.status === 401) {
                        dispatch({
                            type: "SET_UNAUTHORIZED",
                        });
                        return;
                    }
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: err.response.data.error
                                ? err.response.data.error
                                : "Something Went Wrong. Please Try Again Later",
                            type: "error",
                        },
                    });
                    setTimeout(() => {
                        dispatch({
                            type: "SET_POPUP",
                            payload: {
                                text: "",
                                type: "",
                            },
                        });
                    }, popupTimer);
                });
        } else {
            dispatch({
                type: "SET_POPUP",
                payload: {
                    text: "You must Login to Perform this Action",
                    type: "error",
                },
            });
            setTimeout(() => {
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: "",
                        type: "",
                    },
                });
            }, popupTimer);
        }
    };
    const onRatingClick = () => {
        setOpenStatsModal(true);
    };
    const showSpacePopup = (rateId) => {
        if (authorized) {
            setRateId(rateId)
            setOpenSpaceModal(true);
        } else {
            showUnauthorizedError();
        }
    };
    const showRatePopup = () => {
        if (authorized) {
            setShowRateModal(true);
            setId(data.id);
        } else {
            showUnauthorizedError();
        }
    };

    const showUnauthorizedError = () => {
        dispatch({
            type: "SET_POPUP",
            payload: {
                text: "You must Login to Perform this Action",
                type: "error",
            },
        });
        setTimeout(() => {
            dispatch({
                type: "SET_POPUP",
                payload: {
                    text: "",
                    type: "",
                },
            });
        }, popupTimer);
    };
    const handleStatsDialogClose = () => {
        setOpenStatsModal(false);
    };
    const handleRatingModalClose = () => {
        setShowRateModal(false);
    };


    function formatDateToMinutesOnly(timestamp) {
        const date = new Date(timestamp);

        const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed in JS
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${month}/${day}/${year} ${hours}:${minutes}`;
    }

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Card className="cardItem">
            <StatisticsDialog
                data={data.stars}
                total={
                    data.stars &&
                    Object.values(data.stars).reduce(
                        (partial_sum, a, i) => partial_sum + a,
                        0
                    )
                }
                handleClose={handleStatsDialogClose}
                open={openStatsModal}
            />

            <CardHeader
                title={
                    <>
                        <Box>
                            {isBookmark && (
                                <>
                                    <div style={{ display: 'flex' }}>

                                        <Box
                                            className="userName"
                                            style={{ display: 'flex', width: '70%' }}
                                            onClick={() => navigate(`/user/${data.user_id}`)}
                                        >
                                            <Avatar
                                                className="profilePic"
                                                alt="Profile Picture"
                                                style={{
                                                    display: 'flex'
                                                }}
                                                src={data.profile_image_url}
                                                onClick={() => navigate(`/user/${data.user_id}`)}
                                            />
                                            <label style={{ display: 'flex', marginLeft: '10px', marginTop: '5px' }}>
                                                {data.user_name}{" "}
                                            </label>
                                        </Box>
                                        <div style={{ display: 'flex' }}>

                                            <Box
                                                className="card-profile-lvl"
                                                sx={{ marginTop: "2px", "width": '150px', "fontSize": '16px' }}
                                            >
                                                <span style={{ marginLeft: '10px' }}>
                                                    {formatDateToMinutesOnly(data.create_time)}
                                                </span>
                                            </Box>
                                            <Box>
                                                <Button
                                                    id="basic-button"
                                                    aria-controls={open ? "basic-menu" : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? "true" : undefined}
                                                    onClick={handleClick}
                                                    sx={{ minWidth: "24px", padding: "0", ml: 'auto' }}
                                                >
                                                    <MenuIcon />
                                                </Button>
                                            </Box>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Box>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                                marginTop: '20px'
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    width: "100%",
                                }}
                                className="ratingSection"
                            >
                                <div className="d-flex">
                                    {displayStars && (
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={onRatingClick}
                                        >
                                            <Rating
                                                style={{ color: "rgba(255, 204, 72, 1)" }}
                                                name="hover-feedback"
                                                value={data.average_stars}
                                                readOnly
                                                emptyIcon={
                                                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                                                }
                                            />
                                        </div>
                                    )}
                                    {displayStars && (
                                        <Box
                                            style={{
                                                cursor: 'pointer',
                                                fontSize: "15px",
                                                color: "black",
                                                fontWeight: "500",
                                            }}
                                            onClick={onRatingClick}
                                            sx={{ ml: 2, display: 'flex', alignItems: 'center' }}
                                        >
                                            {data.average_stars}
                                            <span
                                                style={{
                                                    fontSize: "15px",
                                                    color: "grey",
                                                    fontWeight: "500",
                                                }}
                                            >
                                                / 5{" "}
                                            </span>
                                        </Box>
                                    )}
                                </div>

                                {/* New line for comment */}
                                {data.comment && (
                                    <Box
                                        sx={{
                                            display: "block",
                                            width: "100%", // Ensures the comment spans the entire width
                                            marginTop: 2, // Adds space between the stars and the comment
                                        }}
                                    >
                                        <PublisherComment comment={data.comment} />
                                    </Box>
                                )}

                                <div style={{ marginLeft: '10px' }}>
                                    <ChipList list={data.tags} highlightList={searhTags} />
                                </div>

                                <Button
                                    sx={{
                                        minWidth: "24px",
                                        borderRadius: "100%",
                                        padding: "4px",
                                        marginLeft: "auto",
                                    }}
                                    className=""
                                    href={data.page_id.href}
                                    target="_blank"
                                >
                                    {/* Button content here */}
                                </Button>
                                {isFeedback && data.resolved && (
                                    <div title={'The customer received an answer'}>
                                        <Done className="iconColor done-feedback-sign" color="primary" />
                                    </div>
                                )}
                            </Box>

                            <div className="weburl-link">

                                <Link
                                    sx={{
                                        color: "#128d6f",
                                        fontWeight: "700",
                                        textDecorationColor: "#128d6f",
                                    }}
                                    href={data.page_id.href}
                                    target="_blank"
                                    title={data.page_id.href}

                                >
                                    {data.page_id.href}
                                </Link>
                            </div>

                            {isFeedback && <TimelineIcon
                                onClick={() => {
                                    setPathDialog(true)
                                }}
                                style={{ color: '#128d6f', 'cursor': 'pointer' }} />
                            }

                        </Box>
                    </>
                }
            />

            <CardContent
                className="cardContent"
                sx={{ position: "relative", width: "100%" }}
            >
                <div style={{ height: '200px' }}>
                    <Description content={data.content}
                        onClickShowMore={() => { setDetailedDataDialog(data) }}

                    />
                </div>




            </CardContent>


            {isBookmark && (
                <>
                    <CardActions disableSpacing sx={{ flexWrap: "wrap", position: "relative" }}>
                        <Grid item>

                        </Grid>


                        {isFeedback && <Button
                            sx={{
                                width: "76px",
                                height: "28px",
                                textAlign: "center",
                                color: "white",
                                fontWeight: "500",
                                backgroundColor: "#128d6f",
                                borderRadius: "50px",
                                marginLeft: "20px",
                            }}
                            className="button-rate"
                            onClick={() => {
                                showRatePopup();
                            }}
                        >
                            Replay
                        </Button>

                        }


                        <Menu

                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >

                            <MenuItem
                                onClick={handleClose}
                                sx={{ paddingTop: "0", paddingBottom: "0" }}
                            >

                                <Chip
                                    className="viewCount"
                                    icon={<QrCodeIcon className="menuIcon" />}
                                    sx={{ color: "rgba(38, 38, 38, 0.5) !important" }}
                                    label={"Add to space"}
                                    onClick={() => showSpacePopup(data.id)}
                                />

                            </MenuItem>
                            {displayRemoveFromSpace && <MenuItem
                                onClick={handleClose}

                            >
                                <Chip
                                    className="viewCount"
                                    icon={<DeleteIcon className="menuIcon" />}
                                    sx={{ color: "rgba(38, 38, 38, 0.5) !important" }}
                                    label={"Remove from current space"}
                                    onClick={() => onRemoveFromSpaceClick(data.id)}
                                />
                            </MenuItem>
                            }
                            {showRemove && <MenuItem
                                onClick={handleClose}

                            >
                                <Chip
                                    className="viewCount"
                                    icon={<DeleteIcon className="menuIcon" />}
                                    sx={{ color: "rgba(38, 38, 38, 0.5) !important" }}
                                    label={"Remove"}
                                    onClick={() => onRemove(data.id)}
                                />
                            </MenuItem>
                            }

                        </Menu>
                        <Box
                            sx={{
                                marginLeft: applyMargin ? { sm: "0px", md: "auto" } : "auto",
                            }}
                        >



                        </Box>
                        <div style={{ display: 'flex' }}>
                            <div style={{
                                cursor: 'pointer',
                                display: 'flex',
                                color: '#128d6f'
                            }}
                                onClick={() => {
                                    setShowComments(!showComments);
                                    setDetailedDataDialog(data)
                                }}
                            >
                            </div>
                            {<Chip
                                style={{
                                    paddingBottom: '10px', display: 'flex', position: "absolute",
                                    bottom: 10,
                                    right: 10,
                                    alignItem: 'center'
                                }}
                                className="viewCount"
                                onClick={() => {
                                    setShowComments(!showComments);
                                    setDetailedDataDialog(data)

                                }}
                                icon={<ChatIcon className="iconColor" color="primary" />}
                                title={'Show conversation'}
                            />}
                        </div>
                    </CardActions>


                </>
            )}
            <PathHistoryDialog open={pathDialogOpen}
                onClose={() => {
                    setPathDialog(false)
                }}
                urls={data.last_urls ? data.last_urls : []}
            />
            <RatingObject
                ratings={ratingsValue}
                open={showRateModal}
                isFeedback={true}
                handleClose={handleRatingModalClose}
                id={id}
                onRateSuccessfully={onRateSuccessfully}
            />
            {openSpaceModal ? (
                <AddToSpace
                    open={openSpaceModal}
                    rateId={rateId}
                    handleClose={handleSpaceModalClose}
                    authorized={authorized}
                />
            ) : null}
            {openedItemData && <HomeCardDialog open={openedItemData} data={openedItemData} onClose={() => setDetailedDataDialog(null)} />}
        </Card>
    );
};
HomeCardItem.defaultProps = {
    displayStars: true,
    displayStats: true
}

export default HomeCardItem;
