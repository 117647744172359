import axios from "axios";

const initialState = {
  user: null,
  authorized: undefined,
  showBusinessForm: false,
  popupTxt: {
    text:"",
    type:""
  },
  lastTabIndex: "",
  lastTabId:""
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOKEN":

      axios.defaults.headers.common["Authorization"] = "Bearer " + action.payload;
      localStorage.setItem('user_token',action.payload)
      return { ...state, token:action.payload};
    case "SET_AUTHORIZED":
      return { ...state, authorized: true, user: action.user };


    case "SET_USER_DATA":

      return { ...state, user: action.payload, authorized: true};

    case "SET_UNAUTHORIZED":
      localStorage.removeItem("user_token")
      axios.defaults.headers.common["Authorization"] = "";
      return {
        ...state,
        authorized: false,
        user: {
          email: "",
          first_name: "",
          last_name: "",
          level: "",
          points: 0,
          token: "",
        },
      };
    case "SET_BUSINESS_FORM":
      return { ...state, showBusinessForm: action.payload };
    case "SET_POPUP":
      return { ...state, popupTxt: action.payload };
    case "SET_LAST_TAB_INDEX":
        return { ...state, lastTabIndex: action.payload };
    case "SET_LAST_TAB_ID":
          return { ...state, lastTabId: action.payload };
    case "GET_SPACES":
      return { ...state, performGetSpaces: action.payload.performGetSpaces };
    default:
      return state;
  }
};
