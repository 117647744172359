import StarsIcon from "@mui/icons-material/Stars";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Grid,
  Box,
  IconButton,
  Typography,
  Input,
  Stack,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import EditableFields from "./EditableFields";
import EditIcon from "@mui/icons-material/Edit";
import "../style.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../consts";
import ConfirmationDialog from "../common/ConfirmationDialog";
import RecentSearches from "../../DummyData/RecentSearches.json";
import ChipList from "../common/ChipList";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Wap from "../../Assets/Wap.svg";
import Level from "../../Assets/Level.svg";
import ProfileUpload from "../../Assets/ProfileUpload.svg";
import ChangePassword from "../ChangePassword/ChangePassword";
import { popupTimer } from "../../consts";

const Profile = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [searchTags, setSearchTags] = useState([]);
  const [data, setData] = useState({
    company_name: "",
    email: "",
    email_preference: {},
    first_name: "",
    last_name: "",
    plan: "",
    points: 0,
    profile_image_url: "",
    oldPassword: "",
    newPassword: "",
    password: "",
  });
  const [dataOriginal, setDataOriginal] = useState({
    company_name: "",
    email: "",
    email_preference: {},
    first_name: "",
    last_name: "",
    plan: "",
    points: 0,
    profile_image_url: "",
    oldPassword: "",
    newPassword: "",
    password: "",
  });
  const [deleteOpen, setDeleteUserDialog] = useState(false);
  const authorized = useSelector((state) => state.authorized);
  const handleClose = () => {
    setShowModal(false);
  };

  const handleFormChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (authorized) {
      console.log('auth')
      let url = API_URL + `/profile`;

      //get Call will be here
      axios
        .get(url)
        .then((res) => {
          setData(res.data?.profile);
          setDataOriginal(res.data?.profile);
          setSearchTags(res.data?.profile?.tags);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            dispatch({
              type: "SET_UNAUTHORIZED",
            });
            return;
          }
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: err.response.data.error
                ? err.response.data.error
                : "Something Went Wrong. Please Try Again Later",
              type: "error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: "SET_POPUP",
              payload: {
                text: "",
                type: "",
              },
            });
          }, popupTimer);
        });
    }
  }, [authorized]);
  const getEncryptPassword = (password) => {
    if (!password) return "";
    let passwordStr = "";
    for (let s of password) {
      passwordStr += "*";
    }
    return passwordStr;
  };
  const setField = (fieldName, field_value) => {
    let cloneData = { ...data };
    cloneData[fieldName] = field_value;
    setData(cloneData);
  };
  const updateProfile = (fieldName, fieldData) => {
    let url = API_URL + `/profile`;
    let formData = { ...data, [fieldName]: fieldData };
    axios
      .put(url, formData)
      .then((res) => {
        if (res.data.delete_response.deleted) {
          dispatch({
            type: "SET_UNAUTHORIZED",
          });
        }
      });
  };
  const imageChange = (e) => {
    let image = e.target.files[0];
    var myReader = new FileReader();
    myReader.onloadend = (e) => {
      setData({ ...data, profile_image_url: myReader.result });
      updateProfile({ profile_image_url: myReader.result });
    };
    myReader.readAsDataURL(image);
  };
  const deleteUser = () => {
    let url = API_URL + `/profile/delete-user`;

    axios
      .delete(url)
      .then((res) => {
        setDeleteUserDialog(false);
        if (res.data.delete_response.deleted) {
          dispatch({
            type: "SET_UNAUTHORIZED",
          });
        }
      });
  };
  if (!authorized)
    return (
      <Box>
        <Grid
          container
          spacing={2}
          style={{ paddingTop: "34px", width: "100%" }}
        >
          <Grid item xs={12} md={12} lg={11}>
            <h2>Profile</h2>
            <h4 className="textMuted">
              Please <a href="/login">sign-in</a> to view your profile
            </h4>
          </Grid>
        </Grid>
      </Box>
    );
  const showComingSoonMsg = () => {
    dispatch({
      type: "SET_POPUP",
      payload: {
        text: "Coming Soon",
        type: "success",
      },
    });
    setTimeout(() => {
      dispatch({
        type: "SET_POPUP",
        payload: {
          text: "",
          type: "",
        },
      });
    }, popupTimer);
  };
  return (
    <Box>
      <Grid container style={{ paddingTop: "34px", width: "100%" }}>
        <Grid item xs={12} sm={10} md={12} lg={11}>
          <Grid item xs={8}>
            <h2>Profile</h2>
            <Box
              style={{
                width: "240px",
                display: "inline-flex",
                marginTop: "20px",
              }}
            >
              <Box className="profile-edit">
                <Avatar
                  className="profileAvatar"
                  alt="Profile Picture"
                  src={data?.profile_image_url}
                />
                <Stack>
                  <label className="upload-img" htmlFor="icon-button-file">
                    <Input
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      onChange={(e) => imageChange(e)}
                      style={{ display: "none" }}
                    />
                    <IconButton
                      style={{ padding: "0" }}
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <Avatar
                        src={ProfileUpload}
                        style={{ height: "18px", width: "18px" }}
                      />
                    </IconButton>
                  </label>
                </Stack>
              </Box>
              <Box
                style={{
                  marginLeft: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <Box style={{fontWeight: 500, padding: "0px 10px 4px"}}>
                                    Pro Rater
                                </Box> */}
                {/* <Box
                  className="profile-level"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Avatar style={{ marginRight: "8px" }} src={Level} />
                  <Typography component="p">Level {data?.level}</Typography>
                </Box> */}

                <Box style={{ width: "250px", display: "inline-flex" }}>
                  <Chip
                    className="pointChip"
                    style={{width:'150px'}}
                    icon={<StarsIcon className="iconStyle" />}
                    label={
                      <Box style={{ marginLeft: "2px" }}>
                        <Box className="pointsStyle" >Points</Box>
                        {/* <Box className="pointsStyle2">{data?.points}</Box> */}
                        <Box className="pointsStyle2">Coming soon</Box>

                      </Box>
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <EditableFields
            label={"First name"}
            value={data?.first_name}
            fieldName={"first_name"}
            handleFormChange={handleFormChange}
            updateField={updateProfile}
          />
          <EditableFields
            label={"Last name"}
            value={data?.last_name}
            fieldName={"last_name"}
            handleFormChange={handleFormChange}
            updateField={updateProfile}
          />

          <EditableFields
            label={"Email"}
            value={data?.email}
            fieldName={"email"}
            handleFormChange={handleFormChange}
            updateField={updateProfile}
          />
          {dataOriginal.email !== data.email && (
            <h5 className={"deleteText"}>
              Email will be change only after get into confirmation link in
              email.
            </h5>
          )}

          <Grid className="followers d-flex">
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography component="h3">{data?.followers}</Typography>
              <Typography component="p">Followers</Typography>
            </Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography component="h3">{data?.followings}</Typography>
              <Typography component="p">Following</Typography>
            </Box>
          </Grid>

          <Grid className="profile-tags">
            <Box className="subHeader">Popular Tags</Box>
            <ChipList list={searchTags?.length ? searchTags : []} />
          </Grid>
        </Grid>

        <Grid item lg={12} className="preference">
          <Box className="subHeader">Email preference</Box>
          <Typography component="p">
            Choose the types of emails you want to receive.
            <Button>Update</Button>
          </Typography>
          <Button onClick={() => setShowModal(true)}>Change Password</Button>
        </Grid>

        <Grid
          className="card"
          container
          xs={12}
          md={10}
          lg={9}
          sx={{ background: "rgb(0 202 153 / 5%)" }}
        >
          <Grid sm={8}>
            <Typography gutterBottom variant="h2" className="h2" component="h2">
              What you can do with your points?
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Button
                className="visit-market button"
                variant="contained"
                onClick={() => showComingSoonMsg()}
              >
                Visit points market
              </Button>
              <Button className="get-points button" variant="outlined">
                How to get points?
              </Button>
            </Box>
          </Grid>
          <Grid sm={4} sx={{ margin: "0 auto" }}>
            <Avatar className="bg-img" src={Wap} />
          </Grid>
        </Grid>

        <Grid item lg={12} className="bottomDiv">
          <Divider />
          <Button
            className="deleteButton"
            color="error"
            onClick={setDeleteUserDialog}
          >
            Delete user
          </Button>
          <Typography className="deleteText">
            This user will no longer be available, and all your saved data will
            be permanently deleted.
          </Typography>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={deleteOpen}
        onClose={() => setDeleteUserDialog(false)}
        onConfirm={() => deleteUser()}
        title={"Delete User Dialog"}
        cancelButtonString={"Cancel"}
        confirmButtonString={"Delete"}
        message={"Are you sure you want to Delete your Current User?"}
      />
      <ChangePassword open={showModal} handleClose={handleClose} />
    </Box>
  );
};

export default Profile;
