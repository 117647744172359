import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {Box, Button, Input} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import HighlightOff from "@mui/icons-material/HighlightOff";
import {pink} from "@mui/material/colors";
import React, {useState} from "react";
const dialogStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '600px',
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
};
const CollaboratorsDialog = ({onAddCollaboratorClick,open,onClose,collaborators,onDeleteCollaboratorClick}) => {
    const [collaboratorEmail, setCollaboratorEmail] = useState("");

    return <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => onClose()}
        closeAfterTransition
    >
        <Fade in={open}>

            <Box sx={dialogStyle}>
                <div>
                    By adding collaborator you allow to another user to access website stats and manage.
                </div>

                <div>
                    <div style={{marginLeft: '20px', display: 'flex'}}>
                        <div style={{width: '300px', marginTop: '30px'}}>
                            <Input
                                fullWidth
                                className="searchBox"
                                // onKeyDown={onTagAdded}
                                value={collaboratorEmail}
                                onChange={(e) => setCollaboratorEmail(e.target.value)}
                                id="input_user"
                                placeholder={'Input your collaborator email'}

                            />
                        </div>
                        <div style={{marginLeft: '20px'}}>
                            <Button
                                onClick={() => {
                                    onAddCollaboratorClick(collaboratorEmail)
                                    setCollaboratorEmail('')
                                }
                                }
                                className="connectButton"
                                variant="contained"
                            >
                                Add collaborator
                            </Button>
                        </div>
                    </div>
                </div>

                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 250}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="left"></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {collaborators.map((c) => (
                                <TableRow>
                                    <TableCell align="left">{c.name}</TableCell>
                                    <TableCell align="left">{c.email}</TableCell>
                                    <TableCell align="left">
                                        <HighlightOff style={{cursor: 'pointer'}} sx={{color: pink[500]}}
                                                      onClick={() => onDeleteCollaboratorClick(c.email)}/>

                                    </TableCell>

                                </TableRow>
                            ))}


                        </TableBody>
                    </Table>
                </TableContainer>


            </Box>

        </Fade>
    </Modal>
}
export default CollaboratorsDialog
