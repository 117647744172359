import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const TermsModal = ({ open,handleClose }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style} className="signup-modal">
          <Typography id="transition-modal-title" variant="h6" component="h2">
            <CloseIcon className="cross-icon" onClick={handleClose} />
            <div className="card-heading mb-81">
            <iframe src={'https://drate.000webhostapp.com/pdfs/terms_and_conditions.html'} frameborder="0" width="100%" height="400px"></iframe>
            </div>
          </Typography>
          <Box id="transition-modal-description" sx={{ mt: 2 }}></Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default TermsModal;
