import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Autocomplete,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import React, { useEffect, useRef, useState } from "react";
import EditableFields from "../Profile/EditableFields";
import "../style.css";
import ReviewForm from "./ReviewForm";
import axios from "axios";
import { API_URL, popupTimer, validateWebsite } from "../../consts";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';


const BusinessForm = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [receivers, setReceivers] = React.useState([]);
  const [submit, setSubmit] = useState(false);
  const [form, setForm] = useState({
    name: "",
    url: "",
    tags: [],
    description: "",
    mode:""
  });
  
  const handleFormChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    setSubmit(false);
  };

  const inputElement = useRef();
  const [navigateToReview, setNavigateToReview] = useState(false);

  const addCompany = (event) => {
    setSubmit(true);
    if (
      form.url &&
      form.name &&
      form.mode && 
      form.description &&
      form.tags.length > 0 &&
      validateWebsite(form.url)
    ) {
      let url = API_URL + `/tenants/websites/`;
      axios
        .post(url, form)
        .then((res) => {
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "New Website Added to Your Account",
              type: "success",
            },
          });
          setTimeout(() => {
            dispatch({
              type: "SET_POPUP",
              payload: {
                text: "",
                type: "",
              },
            });
          }, popupTimer);
          navigate(`/website/` + res.data.website.id);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            dispatch({
              type: "SET_UNAUTHORIZED",
            });
            return;
          }
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: err.response.data.error
                ? err.response.data.error
                : "Something Went Wrong. Please Try Again Later",
              type: "error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: "SET_POPUP",
              payload: {
                text: "",
                type: "",
              },
            });
          }, popupTimer);
        });
    }
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setForm({ ...form, image: URL.createObjectURL(event.target.files[0]) });
    }
  };
  if (navigateToReview) {
    return <ReviewForm setForm={setForm} form={form} />;
  }
  return (
    <Grid item xs={12} md={12} lg={11}>
      {/* <input
                type="file"
                name="filename"
                accept="image/gif, image/jpeg, image/png"
                style={{display: "none"}}
                ref={inputElement}
                onChange={onImageChange}
            />

            <Badge
                overlap="circular"
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                badgeContent={
                    <IconButton
                        onClick={() => {
                            inputElement.current.click();
                        }}
                        aria-label="delete"
                    >
                        <AddCircleIcon color="primary"/>
                    </IconButton>
                }
            >
                <Avatar
                    className="profileAvatar"
                    alt="Travis Howard"
                    src={form.image}
                />
            </Badge> */}

      <TextField
        label="Website Name"
        placeholder="Website Name"
        name="name"
        fullWidth
        className="businessAccountInputStyle"
        onChange={handleFormChange}
        error={submit && form.name === "" ? true : false}
        helperText={submit && form.name === "" ? "Field is Required" : ""}
        variant="standard"
      />
      <TextField
        label="Website Link"
        placeholder="Website Link"
        name="url"
        fullWidth
        className="businessAccountInputStyle"
        onChange={handleFormChange}
        error={
          submit && (form.url === "" || !validateWebsite(form.url))
            ? true
            : false
        }
        helperText={
          submit && form.url === ""
            ? "Field is Required"
            : submit && !validateWebsite(form.url)
            ? "Please Enter Correct Website Link"
            : ""
        }
        variant="standard"
      />
      <TextField
        label="Description"
        placeholder="Description"
        name="description"
        fullWidth
        className="businessAccountInputStyle"
        onChange={handleFormChange}
        multiline
        rows={4}
        error={submit && form.description === "" ? true : false}
        helperText={
          submit && form.description === "" ? "Field is Required" : ""
        }
        variant="standard"
      />

      <Autocomplete
        multiple
        onChange={(e, newval, reason) => {
          setForm({ ...form, tags: newval });
        }}
        id="tags-filled"
        options={[]}
        className="businessAccountInputStyle"
        defaultValue={[]}
        clearOnBlur={true}
        value={form.tags}
        name={"tags"}
        sx={{ minWidth: "40%" }}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            onChange={(e) => setSubmit(false)}
            placeholder={"Website Tags"}
            error={submit && form.tags.length === 0 ? true : false}
            helperText={
              submit && form.tags.length === 0 ? "Field is Required" : ""
            }
            onBlur={(e) => {
              if (e.target.value !== "") {
                setForm({
                  ...form,
                  tags: form.tags.concat(e.target.value),
                });
              }
            }}
          />
        )}
      />
       <FormControl error={submit && form.mode === "" ? true : false}>
      <FormLabel id="demo-controlled-radio-buttons-group">Mode</FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="mode"
        value={form.mode}
        onChange={handleFormChange}
        sx={{display:"block"}}
      >
        <FormControlLabel value="rate" control={<Radio />} label="Rate" />
        <FormControlLabel value="feedback" control={<Radio />} label="Feedback" />
      </RadioGroup>
      {submit && form.mode === "" ? 
      <FormHelperText sx={{marginLeft:"0px"}}>Please select an option.</FormHelperText>
          : null}
    </FormControl>
    <br/>
      <Button
        className="connectButton"
        variant="contained"
        onClick={() => {
          addCompany();
        }}
      >
        Add Website
      </Button>
    </Grid>
  );
};

export default BusinessForm;
