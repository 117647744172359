import ChatIcon from "@mui/icons-material/Chat";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SendIcon from "@mui/icons-material/Send";
import StarIcon from "@mui/icons-material/Star";
import axios from "axios";
import {
  Avatar,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Rating,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Link from "@mui/material/Link";
import React, { useState, useEffect } from "react";
import ChipList from "./ChipList";
import { API_URL, popupTimer } from "../../consts";
import { useDispatch } from "react-redux";
import profileLvl from "../../Assets/card-lvl.svg";
import MarksComments from "./MarksComments";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
function formatDateToMinutesOnly(timestamp) {
  const date = new Date(timestamp);

  const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed in JS
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${month}/${day}/${year} ${hours}:${minutes}`;
}
const CommentItem = ({ isFeedback,data, handleLike, parentId, addComment }) => {
  const navigate = useNavigate();
  const authorized = useSelector(({ authorized }) => authorized);
  const [openComment, setOpenComment] = useState(false);
  const [comment, setComment] = useState("");
  const [commenterr, setCommenterr] = useState(false);
  const [peopleMarks, setPeopleMarks] = useState([]);
  const [like, setLike] = useState(false);
  const [hideMore, setHideMore] = useState(true);
  const dispatch = useDispatch();

  const getAllComments = () => {
    let url = API_URL + `/rates/${parentId}/marks/${data.id}/comments`;
    axios
      .get(url)
      .then((res) => {
        setPeopleMarks(res.data.commentes);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch({
            type: "SET_UNAUTHORIZED",
          });
          return;
        }
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: err.response.data.error
              ? err.response.data.error
              : "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "",
              type: "",
            },
          });
        }, popupTimer);
      });
  };

  const sendComment = () => {
    if (!comment.length) {
      setCommenterr(true);
      return;
    }
    if (authorized) {
      let url = API_URL + `/rates/${parentId}/marks/${data.id}/comments`;
      const obj = {
        rated_object_id: parentId,
        rating_id: data.id,
        comment: comment,
      };
      axios
        .post(url, obj)
        .then((res) => {
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "Comment Added Successfully",
              type: "success",
            },
          });
          setTimeout(() => {
            dispatch({
              type: "SET_POPUP",
              payload: {
                text: "",
                type: "",
              },
            });
          }, popupTimer);
          setComment("");
          setCommenterr(false);
          getAllComments();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            dispatch({
              type: "SET_UNAUTHORIZED",
            });
            return;
          }
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: err.response.data.error
                ? err.response.data.error
                : "Something Went Wrong. Please Try Again Later",
              type: "error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: "SET_POPUP",
              payload: {
                text: "",
                type: "",
              },
            });
          }, popupTimer);
        });
    } else {
      dispatch({
        type: "SET_POPUP",
        payload: {
          text: "You must Login to Perform this Action",
          type: "error",
        },
      });
      setTimeout(() => {
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: "",
            type: "",
          },
        });
      }, popupTimer);
    }
  };

  const handleClickLike = () => {
    handleLike(!like);
    setLike(!like);
  };

  return (
    <Grid container className="commentItem" style={{ position: "relative" }}>
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={12} sm={8}>
          <Grid item xs={12}>
            <Box className="profileDiv">
              {<Box onClick={() => navigate(`/user/${data.user_id}`)}>
                <Avatar
                  className="profilePic"
                  alt="Profile Picture"
                  src={data.profile_image_url}
                />
              </Box>}
              <Box className="profileDesc">
                {<Box sx={{display: "flex"}}>
                  <Box
                      className="userName"
                      onClick={() => navigate(`/user/${data.user_id}`)}
                  >

                    {data.user_name}{" "}
                  </Box>
                  {/*{!isFeedback && <Box className="card-profile-lvl" sx={{marginLeft: "9px"}}>*/}
                  {/*  <Avatar*/}
                  {/*      src={profileLvl}*/}
                  {/*      sx={{*/}
                  {/*        width: "20px",*/}
                  {/*        height: "20px",*/}
                  {/*        marginRight: "3px",*/}
                  {/*      }}*/}
                  {/*  />*/}
                  {/*  <Typography*/}
                  {/*      component="p"*/}
                  {/*      sx={{textTransform: "uppercase"}}*/}
                  {/*  >*/}
                  {/*    lvl 1*/}
                  {/*  </Typography>*/}
                  {/*</Box>*/}
                  {/*}*/}
                </Box>
                }

                {<Box className="proRater">{formatDateToMinutesOnly(data.create_time)}</Box>}
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    style={{ color: "rgba(255, 204, 72, 1)" }}
                    name="hover-feedback"
                    value={data.average_stars}
                    precision={0.5}
                    readOnly
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            textAlign: { xs: "center", sm: "right" },
            marginTop: "0.75rem",
            marginBottom: { xs: "5px", sm: "0px" },
          }}
        >
          <Typography
            paragraph
            sx={{
              color: "rgba(38, 38, 38, 0.5)",
              fontSize: "12px",
              marginBottom: "0px",
            }}
          >
            {!isFeedback &&new Date(data.create_time).toLocaleDateString()}
          </Typography>

          {/* <Chip
            className="viewCount"
            icon={
              <FavoriteIcon
                className={`iconColor likeIcon ${like ? " liked" : ""}`}
                color="primary"
              />
            }
            onClick={() => handleClickLike(true)}
            label={data.likes_count}
          /> */}
          {!isFeedback &&<Chip
            className="viewCount"
            icon={<ChatIcon className="iconColor" color="primary" />}
            onClick={() => {
              setOpenComment(true);
              getAllComments();
            }}
            label={data.comments_count}
          />}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {!isFeedback && <ChipList list={data.tags} highlightList={data.tags} />}
        <Typography
          className="desc-text"
          variant="body2"
          color="text.primary"
          sx={{ marginTop: "18px" }}
        >
          {data?.comment?.length > 300 ? (
            hideMore ? (
              <p>
                {data?.comment?.slice(0, 300)}...
                <Link
                  sx={{
                    cursor: "pointer",
                    color: "#128d6f",
                    fontWeight: "700",
                    textDecorationColor: "#128d6f",
                  }}
                  onClick={(e) => setHideMore(false)}
                >
                  Read More
                </Link>
              </p>
            ) : (
              <>
                {" "}
                <p>
                  {" "}
                  {data?.comment}{" "}
                  <Link
                    sx={{
                      cursor: "pointer",
                      fontWeight: "700",
                      color: "#128d6f",
                      textDecorationColor: "#128d6f",
                    }}
                    onClick={(e) => setHideMore(true)}
                  >
                    Show Less
                  </Link>
                </p>
              </>
            )
          ) : (
            <>{data?.comment}</>
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          color="text.primary"
          className="commentDesc"
        >
          {data.description}
        </Typography>
      </Grid>
      {openComment ? (
        <Grid
          item
          xs={12}
          style={{
            paddinTop: "10px !important",
            borderLeft: "1px solid #F3F3F3",
            padding: "15px",
            height: "200px",
            overflowY: "auto",
          }}
        >
          <FormControl
            error={commenterr}
            helperText={commenterr ? "Required" : ""}
            fullWidth
            variant="standard"
          >
            <InputLabel htmlFor="standard-adornment-password">
              Comment
            </InputLabel>
            <Input
              className="loginTextfields"
              value={comment}
              fullWidth
              name="password"
              onChange={(e) => setComment(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={sendComment}>
                    <SendIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {peopleMarks.map((data, ind) => (
            <MarksComments key={ind} data={data} />
          ))}
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default CommentItem;
