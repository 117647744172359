import {
  Grid,
  IconButton,
  Button,
  Input,
  Typography,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { validateWebsite } from "../../consts";

import "../style.css";

const EditableFields = ({
  label,
  value,
  onChange,
  textBox,
  handleFormChange,
  fieldName,
  updateField,
}) => {
  const [edit, setEdit] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [error, setError] = useState("");
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Grid item xs={8} className="editable">
      <Typography className="profileItemHeader">{label}</Typography>
      <Typography className="paddingStyle d-flex">
        {edit ? (
          <TextField
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              handleFormChange(e);
              setError("");
            }}
            name={fieldName}
            error={!!error}
            helperText={error}
            multiline={textBox ? true : false}
            rows={textBox ? 4 : 1}
            sx={{width:"100%"}}
            variant="standard"
          />
        ) : (
          inputValue
        )}
        <IconButton
          style={{ borderRadius: "0", padding: "0", marginLeft: "8px" }}
          onClick={() => {
            if (edit && inputValue === "") {
              setError("Field is Required");
            }
            else if(fieldName ==='url' && !validateWebsite(inputValue)){
              setError("Please Enter Correct Website Link, Include prefix (https://, http://)");
            }
            else if (edit && inputValue) {
              updateField(fieldName,inputValue );
              setEdit(!edit);
            } else {
              setEdit(!edit);
            }
          }}
        >
          {edit ? (
            <Button color="primary" style={{}}>
              <SaveIcon color="primary" />
            </Button>
          ) : (
            // eslint-disable-next-line react/jsx-no-undef
            <Button color="primary" style={{ borderRadius: "0" }}>
              <EditIcon color="primary" />
            </Button>
          )}
        </IconButton>
      </Typography>
    </Grid>
  );
};

export default EditableFields;
