import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  TextField,
} from "@mui/material";
import Card from "@mui/material/Card";
import React, { useState } from "react";
import "../style.css";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import facebook from "../../Assets/facebook.png";
import Checkbox from "@mui/material/Checkbox";
import apple from "../../Assets/apple.png";
import google from "../../Assets/google.png";
import Alert from "@mui/material/Alert";

import StarsIcon from "@mui/icons-material/Stars";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { API_URL } from "../../consts";
import { Auth } from "aws-amplify";
import TermsModal from "../Terms/Terms";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";

const SignUp = ({ setSignUp,onClickTermsAndConditions }) => {
  const [form, setForm] = useState({
    email: "",
    showPassword: false,
    password: "",
    terms: "",
  });
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [errObj, setErrObj] = useState({ email: "" });

  const [emailValidated, setEmailValidated] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const handleFormChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const handleFormCheckbox = (e) => {
    setForm({ ...form, [e.target.name]: e.target.checked });
  };

  const signInWithGoogle = () => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  };

  const validateEmail = (email) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  };
  const continueWithEmail = () => {
    let val = validateEmail(form.email);
    setEmailValidated(val);
    setErrObj({ email: !val });
  };

  const handleClickShowPassword = () => {
    setForm({
      ...form,
      showPassword: !form.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const signIn = () => {
    setSignUp(false);
  };

  const signUp = () => {
    let url = API_URL + "/security/signup";

    // TODO Need to go to a page telling you to verify your account in email
    // TODO terms_and_conditions_accepted should be checked in frontend
    try {
      const signUpResponse = Auth.signUp({
        username: form.email,
        password: form.password,
        attributes: {
          email: form.email,
        },
      })
        .then((res) => {
          setFormError("");
          axios
            .post(url, {
              user_id: res.userSub,
              email: form.email,
              password: form.password,
              terms_and_conditions_accepted: form.terms,
            })
            .then((res) => {
              if (res.data.signup.succeed) {
                setFormSuccess('Please Check your Email to Complete the Registration.')
                setTimeout(()=>{
                  signIn();
                },2500)
              }
            })
            .catch((err) => {
              setFormSuccess("")
            });
        })
        .catch((error) => {
          setFormSuccess("")
          setFormError(error.message);
        });
    } catch (error) {
      setFormSuccess("")
      setFormError(error.message);
    }
  };
  const changeToLoginPage = () => {
    setFormError("");
    setFormSuccess("")
    setSignUp(false);
  };

  return (
    <Grid container style={{ justifyContent: "center" }}>
      <Grid
        item
        xs={12}
        style={{ display: "flex", alignItems: "center", padding: "5px 20px" }}
      >
        <IconButton size="large" edge="start" color="inherit" aria-label="menu">
          <StarsIcon className="iconStyle" />
        </IconButton>
        <Typography variant="h6" className="dataRateHeader">
          Data Rate
        </Typography>
      </Grid>
      {/* <Grid item xs={4}></Grid> */}
      <Grid item className="loginCard">
        {!!formError ? <Alert severity="error">{formError}</Alert> : null}
        {!!formSuccess ? <Alert severity="success">{formSuccess}</Alert> : null}
        <Card style={{ padding: "0px 8px", borderRadius: "15px" }}>
          <CardContent>
            <div className="signInHeader">
              <h2 className="signIn"> Sign Up</h2>
              <Link
                className="dont_have_account"
                component="button"
                color="primary"
                onClick={changeToLoginPage}
              >
                I have an account
              </Link>
            </div>
            <TextField
              name={"email"}
              hiddenLabel
              fullWidth
              className="loginTextfields"
              id="standard-basic"
              label={"Email"}
              value={form.email}
              onChange={handleFormChange}
              variant="standard"
              error={Boolean(errObj["email"])}
              helperText={
                !emailValidated
                  ? !form.email.length
                    ? "Required"
                    : errObj["email"]
                    ? "Invalid Email Address"
                    : ""
                  : ""
              }
            />

            <FormControl
              className="loginTextfields"
              fullWidth
              variant="standard"
            >
              <InputLabel htmlFor="standard-adornment-password">
                Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={form.showPassword ? "text" : "password"}
                value={form.password}
                fullWidth
                name="password"
                onChange={handleFormChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {form.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <div style={{ display: "flex", marginLeft: "-12px" }}>
              <Checkbox {...label} onChange={handleFormCheckbox} name="terms" />
              <p style={{ marginTop: "auto", marginBottom: "auto" }}>
                I Accept the{" "}
                <Link

                  sx={{ marginLeft: "2px", cursor: "pointer" }}
                  onClick={()=>onClickTermsAndConditions()}
                >
                  Terms & Conditions
                </Link>
              </p>
            </div>

            {!(emailValidated && form.email.length) ? (
              <Button
                className="connectButton"
                variant="contained"
                fullWidth
                disabled={!!form.terms ? false : true}
                onClick={() => {
                  continueWithEmail();
                }}
              >
                Continue
              </Button>
            ) : (
              <Button
                className="signUpButton"
                variant="contained"
                fullWidth
                disabled={!!form.terms ? false : true}
                onClick={() => {
                  signUp();
                }}
              >
                Sign up
              </Button>
            )}

            <Link className="signin_with" component="button" onClick={() => {}}>
              OR SIGN UP WITH
            </Link>
            <Grid container spacing={2}>
              <Grid textAlign="center" item lg={4} md={4} xs={12}>
                {/*<Button*/}
                {/*  className="loginButtons"*/}
                {/*  variant="outlined"*/}
                {/*  startIcon={*/}
                {/*    <Avatar*/}
                {/*      sx={{ height: "20px", width: "20px" }}*/}
                {/*      src={facebook}*/}
                {/*    />*/}
                {/*  }*/}
                {/*>*/}
                {/*  FACEBOOK*/}
                {/*</Button>*/}
              </Grid>
              <Grid textAlign="center" item lg={4} md={4} xs={12}>
                <Button
                  className="loginButtons"
                  variant="outlined"
                  onClick={() => {
                    signInWithGoogle();
                  }}
                  startIcon={
                    <Avatar
                      sx={{ height: "20px", width: "20px" }}
                      src={google}
                    />
                  }
                >
                  GOOGLE
                </Button>
              </Grid>
              {/*<Grid textAlign="center" item lg={4} md={4} xs={12}>*/}
              {/*  <Button*/}
              {/*    className="loginButtons"*/}
              {/*    variant="outlined"*/}
              {/*    startIcon={*/}
              {/*      <Avatar*/}
              {/*        sx={{ height: "20px", width: "20px" }}*/}
              {/*        src={apple}*/}
              {/*      />*/}
              {/*    }*/}
              {/*  >*/}
              {/*    APPLE*/}
              {/*  </Button>*/}
              {/*</Grid>*/}
            </Grid>
            {/* <Grid container>
              <Grid item xs={12} style={{ padding: "0px 10px" }}>
                <Link className="terms_conds2" component="button">
                  By signing up, you agree to the Terms and Conditions and
                  Privacy Policy
                </Link>
              </Grid>
            </Grid> */}
            <div>
              <p className="terms-point">
                By signing up, you agree to the
                <Link className="terms_conds" component="button"
                      onClick={()=>{onClickTermsAndConditions()}}
                >
                  Terms and Conditions
                </Link>
                and
                <Link className="terms_conds" component="button"
                      onClick={()=>{onClickTermsAndConditions()}}

                >
                  Privacy Policy
                </Link>
              </p>
            </div>
          </CardContent>
          <CardActions></CardActions>
        </Card>
      </Grid>
      {/* <Grid item xs={4}></Grid> */}
      <TermsModal open={showModal} handleClose={handleClose} />
    </Grid>
  );
};

export default SignUp;
