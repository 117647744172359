import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import AnalyticsMainPageContent from "./AnalyticsMainPageContent";
import axios from "axios";
import { API_URL } from "../../consts";

const TrendsAndAnalytics = () => {
  const [formOpen, setFormOpen] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    //get Call will be here
    // getAnalytics();
  }, []);

  const getAnalytics = () => {
    let url = API_URL + `/tenant`;

    axios
      .get(url)
      .then((res) => {
        setData(res.data.tenant);
        if (res.data.tenant.plan) {
          setFormOpen(true);
        }
      });
  };

  const openBusinessForm = () => {
    setFormOpen(true);
  };
  return (
    <Grid container spacing={2} style={{ paddingTop: "34px" }}>
      <Grid item xs={12} md={12} lg={11}>
        <h2>Trends & Analytics</h2>
        <AnalyticsMainPageContent openBusinessForm={openBusinessForm} />
      </Grid>
      {/* <Grid item xs={4}></Grid> */}
    </Grid>
  );
};

export default TrendsAndAnalytics;
