import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid, Avatar } from "@mui/material";
import { API_URL } from "../../consts";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { useDispatch } from "react-redux";
import axios from "axios";
import { popupTimer } from "../../consts";
import space1 from "../../Assets/space-1.svg";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

const AddToSpace = ({ open, handleClose, authorized,rateId }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    space: "",
  });
  const [submit, setSubmit] = useState(false);
  const [spaces, setSpaces] = React.useState([]);
  const handleFormChange = (e) => {
    setSubmit(false);
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (authorized) {
      getSpaces();
    }
  }, []);
  const getSpaces = () => {
    let url = API_URL + `/spaces`;
    axios
      .get(url)
      .then((res) => {
        setSpaces(res.data.spaces);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch({
            type: "SET_UNAUTHORIZED",
          });
          return;
        }
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: err.response.data.error
              ? err.response.data.error
              : "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "",
              type: "",
            },
          });
        }, popupTimer);
      });
  };

  const handleFormSubmit = () => {
    setSubmit(true);
    if (form.space) {
      let url = API_URL + `/spaces/${form.space}/rates`;
      let data = {
        "rate_id":rateId
      }
      axios
        .put(url, data)
        .then((res) => {
          handleClose();
          setForm({
              space:""
          })
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "Element Added to the Space Successfully",
              type: "success",
            },
          });
          setTimeout(() => {
            dispatch({
              type: "SET_POPUP",
              payload: {
                text: "",
                type: "",
              },
            });
          }, popupTimer);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            dispatch({
              type: "SET_UNAUTHORIZED",
            });
            return;
          }
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: err.response.data.error
                ? err.response.data.error
                : "Something Went Wrong. Please Try Again Later",
              type: "error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: "SET_POPUP",
              payload: {
                text: "",
                type: "",
              },
            });
          }, popupTimer);
        });
    }
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style} className="signup-modal">
          <Typography id="transition-modal-title" variant="h6" component="h2">
            <CloseIcon
              className="cross-icon"
              onClick={() => {
                handleClose();
                setSubmit(false);
                setForm({
                    space:""
                })
              }}
            />
            <div className="card-heading mb-81"></div>
          </Typography>
          <Box id="transition-modal-description">
            <Typography
              id="transition-modal-title"
              variant="h2"
              component="h2"
              sx={{ fontSize: "20px", textAlign: "center", fontWeight: "700" }}
            >
              Which Space you want to Add the Element to?
            </Typography>
            <FormControl variant="standard" sx={{ m: 1 }} fullWidth>
              <InputLabel id="demo-simple-select-standard-label">
                Space
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={form.space}
                className="customSelect"
                name="space"
                error={submit && form.space === "" ? true : false}
                onChange={handleFormChange}
                label="Space"
              >
                {spaces.map((space, index) => (
                  <MenuItem value={space.id}>
                    <Avatar
                      src={space1}
                      sx={{ width: "40px", height: "40px", mr: 2 }}
                    />
                    {space.name}
                  </MenuItem>
                ))}
              </Select>
              {submit && form.space === "" ? (
                <FormHelperText sx={{color:"#d32f2f"}}>Field is Required</FormHelperText>
              ) : null}
            </FormControl>

            <Grid container sx={{ margin: "0 auto" }}>
              <Grid item sx={{ margin: "0 auto" }}>
                <Button
                  className="connectButton"
                  onClick={handleFormSubmit}
                  variant="contained"
                >
                  Add Element
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddToSpace;
