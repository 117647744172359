import React from "react";
import {Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import "../style.css";

const ConfirmationDialog = ({open, onClose, onConfirm, message, title, cancelButtonString, confirmButtonString}) => {
    return (<Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>{cancelButtonString}</Button>
            <Button onClick={onConfirm} color="error">
                {confirmButtonString}
            </Button>
        </DialogActions>
    </Dialog>)
}
export default ConfirmationDialog;
