import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder"; // Updated Icon
import { Toolbar } from "@mui/material";
import PersonOutline from "@mui/icons-material/PersonOutline"; // Import outlined Person icon (for profile)
import StarOutline from "@mui/icons-material/StarOutline";
import AddIcon from "@mui/icons-material/Add"; // Added Plus Icon
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { API_URL, popupTimer } from "../../consts";
import axios from "axios";
import CreateSpace from "../Space/CreateSpace";

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MiniSidebar = ({ setProfile, animation }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [isCreateSpaceOpen, setIsCreateSpaceOpen] = React.useState(false); // State to control CreateSpace visibility
  const location = useLocation();
  const navigate = useNavigate();
  const [spaces, setSpaces] = React.useState([]);

  const sidebarItems = [
    { title: "My Reviews", icon: <StarOutline sx={{ fontSize: 20, color: 'rgba(0, 0, 0, 0.6)' }} />, path: "/my-reviews" },
    {
      title: "My Spaces",
      icon: <GroupsOutlinedIcon sx={{ fontSize: 20, color: 'rgba(0, 0, 0, 0.6)' }} />, // No icon for "My Spaces"
    }
  ];

  const sidebarItemsEnd = [
    { title: "Profile", icon: <PersonOutline sx={{ fontSize: 20, color: 'rgba(0, 0, 0, 0.6)' }} />, path: "/profile" }
  ];
  const token = useSelector((state) => state.token);


  React.useEffect(() => {
    if(token){
      getSpaces();

    }
    
  }, [token]);

  const dispatch = useDispatch();

  const performGetSpaces = useSelector((state) => state.performGetSpaces);
  // todo =401 error in enter
  React.useEffect(()=>{
    if(performGetSpaces){
      console.log('perf')
      getSpaces()
      dispatch({
        type: "GET_SPACES",
        payload: {
            performGetSpaces:false
        },
    })
    }

  },[performGetSpaces])
  const getSpaces = () => {
    let url = API_URL + `/spaces`;
    axios
        .get(url)
        .then((res) => {
          setSpaces(res.data.spaces);
        })
        .catch((err) => {
          console.error(err);
        });
  };

  return (
      <Drawer
          variant="permanent"
          transitionDuration={3000}
          open={open}
          sx={{
            display: { xs: "none", sm: "block" },
          }}
      >
        <Toolbar sx={{ marginTop: "16px" }} />
        <List
            onMouseEnter={() => (!animation ? setOpen(true) : null)}
            onMouseLeave={() => (!animation ? setOpen(false) : null)}
        >
          {sidebarItems.map((item, index) => (
              <ListItemButton
                  key={item.title}
                  sx={{
                    minHeight: 36,
                    justifyContent: open ? "initial" : "center",
                    px: 3,
                    cursor: item.path ? 'pointer' : 'default', // Make "My Spaces" not clickable
                  }}
                  className={
                    item.path === location.pathname
                        ? "highlightedSidebar"
                        : "sidebarItem"
                  }
                  onClick={() => {
                    if (item.path) {
                      navigate(item.path);
                      setProfile(false);
                    }
                  }}
              >
                <ListItemIcon
                    sx={{
                      minWidth: '20px',
                      justifyContent: "center",
                      marginRight: '10px', // Add spacing
                    }}
                    className={
                      item.path === location.pathname ? "iconColor" : "sidebarItem"
                    }
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    className={
                      item.path === location.pathname
                          ? "sidebarItemHigh"
                          : "sidebarItem"
                    }
                    primary={item.title}
                    sx={{
                      marginRight: '10px',
                      fontWeight: 300,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: open ? '200px' : '0px'  // Adjust based on drawer width
                    }}
                    title={item.title}  // Show full title on hover
                />
                {item.title === "My Spaces" &&open&& (
                    <ListItemIcon
                        sx={{
                          minWidth: '20px',
                          marginLeft: 'auto', // Align to the right
                          justifyContent: "center",
                        }}
                        onClick={() => setIsCreateSpaceOpen(true)} // Open CreateSpace on click
                    >
                      <AddIcon sx={{ fontSize: 20, color: 'rgba(0, 0, 0, 0.6)', cursor: 'pointer' }} />
                    </ListItemIcon>
                )}
              </ListItemButton>
          ))}
          {open&&spaces.map((space) => (
              <ListItemButton
                  key={space.id}
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    px: 8,
                  }}
                  className={
                    location.pathname.includes(`/spaces/${space.id}`)
                        ? "highlightedSidebar"
                        : "sidebarItem"
                  }
                  onClick={() => {
                    navigate(`/spaces/${space.id}`);
                    setProfile(false);
                  }}
              >
                <ListItemText
                    disableTypography
                    className={
                      location.pathname.includes(`/spaces/${space.id}`)
                          ? "sidebarItemHigh"
                          : "sidebarItem"
                    }
                    primary={space.name}
                    sx={{
                      opacity: open ? 1 : 0,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: open ? '200px' : '0px'  // Adjust based on drawer width
                    }}
                    title={space.name}  // Show full space name on hover
                />
              </ListItemButton>
          ))}

          {sidebarItemsEnd.map((item, index) => (
              <ListItemButton
                  key={item.title}
                  sx={{
                    minHeight: 36,
                    justifyContent: open ? "initial" : "center",
                    px: 3,
                    cursor: item.path ? 'pointer' : 'default', // Make "My Spaces" not clickable
                  }}
                  className={
                    item.path === location.pathname
                        ? "highlightedSidebar"
                        : "sidebarItem"
                  }
                  onClick={() => {
                    if (item.path) {
                      navigate(item.path);
                      setProfile(false);
                    }
                  }}
              >
                <ListItemIcon
                    sx={{
                      minWidth: '20px',
                      justifyContent: "center",
                      marginRight: '10px', // Add spacing
                    }}
                    className={
                      item.path === location.pathname ? "iconColor" : "sidebarItem"
                    }
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    className={
                      item.path === location.pathname
                          ? "sidebarItemHigh"
                          : "sidebarItem"
                    }
                    primary={item.title}
                    sx={{
                      marginRight: '10px',
                      fontWeight: 300,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: open ? '200px' : '0px'  // Adjust based on drawer width
                    }}
                    title={item.title}  // Show full title on hover
                />
              </ListItemButton>
          ))}
        </List>
        {isCreateSpaceOpen && <CreateSpace open={isCreateSpaceOpen}
         handleClose={() => setIsCreateSpaceOpen(false)}
         onSpaceAdded={()=>{
          getSpaces()
          setIsCreateSpaceOpen(false)
         }
         }
          />}
      </Drawer>
  );
};

export default MiniSidebar;
