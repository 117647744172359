import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";
import ChipList from "../common/ChipList";
import StarsIcon from "@mui/icons-material/Stars";
import {API_URL, popupTimer} from "../../consts";
import axios from "axios";
import HomeCardItem from "../Home/HomeCardItem";
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import {useNavigate} from "react-router";
import {useSelector, useDispatch} from "react-redux";
import BusinessAccount from "../BusinessAccount/BusinessAccount";
import SettingsIcon from '@mui/icons-material/Settings';

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const MySpaces = () => {
    const authorized = useSelector((state) => state.authorized);
    React.useEffect(() => {
        if (authorized) {
            getRatesRequest();

        }

    }, authorized);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const [data, setData] = React.useState({'rates': [], 'recent_rates': [], 'recent_feedbacks': []});
    const [stats, setStats] = React.useState();
    const [featuresActivity, setFeaturesActivity] = React.useState({});

    const [searchTags, setSearchTags] = React.useState([]);
    const [tabsData, setTabsData] = React.useState([]);
    const [tabId, setTabId] = React.useState(0);
    const [statsData, setStatsData] = React.useState([]);
    const [id, setId] = React.useState("");
    const {lastTabIndex} =  useSelector((state) => state)
    const {lastTabId} =  useSelector((state) => state)

    React.useEffect(()=>{
        if(lastTabIndex){
            setValue(lastTabIndex)
        }
    },[lastTabIndex])
    React.useEffect(() => {
        //get Call will be here
        if (window.location.search) {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            setId(parseInt(params.get("id")));
            let tab = parseInt(params.get("tab"));
            setValue(tab);
        }
        if (authorized) {
            getRatesRequest();

        }
        // getStats()
    }, []);

    const onRateSuccessfully = (id) => {
        let url = API_URL + `/rates/${id}`;
        axios
            .get(url)
            .then((res) => {
                const ratedObj = res.data.rated_object.rate;
                let currentData  ={...data}
                let currentDataFeedbacks  =data.recent_feedbacks.slice()

                let index = currentDataFeedbacks.findIndex(element => element.id === id);
                currentDataFeedbacks[index] = ratedObj
                currentData['recent_feedbacks'] =currentDataFeedbacks
                setData(currentData)
                // setData(data.recent_rates.map((obj) => (obj.id === id ? (obj = ratedObj) : obj)));
                // setData(data.recent_feedbacks.map((obj) => (obj.id === id ? (obj = ratedObj) : obj)));
                // setData(data.rates.map((obj) => (obj.id === id ? (obj = ratedObj) : obj)));
            })
            .catch((err) => {
                if(!err.response)
                {
                    return;
                }
                if (err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });
    };
    const setBusinessForm = () => {
        navigate("/add-space");
    };
    const timeConvert = (time) => {
        var num = time;
        var hours = num / 60;
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + "h " + rminutes + "m";
    };

    const getStatsData = (id) => {
        let url = API_URL + `/tenants/stats?website_id=${id}`;
        axios
            .get(url)
            .then((res) => {
                // console.log(res.data.stats)
                dispatch({
                    type: "SET_LAST_TAB_ID",
                    payload: id
                });
                setStats(res.data.stats);
                setFeaturesActivity(res.data.features_activity);
                setTabId(id);
                getRatesData(id);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });
    };
    const getRatesData = (id) => {
        let url = API_URL + `/tenants/space/${id}/rates`;
        axios
            .get(url)
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });
    };

    const getRatesRequest = () => {
        let url = API_URL + `/tenants/space`;
        axios
            .get(url)
            .then((res) => {
                setTabsData(res.data.space);
                if (id !== "") {
                    getStatsData(id);
                }
                else if(lastTabId){
                    getStatsData(lastTabId);
                }
                 else if (res.data.space?.length > 0) {
                    getStatsData(res.data.space[0].id);
                } else {
                    navigate("/add-space");
                }
                // setData(res.data.rates);
                // setTabId(res.data.space?.length > 0 ? res.data.space[0].id :0)
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });
    };
    const handleChange = (event, newValue) => {
        dispatch({
            type: "SET_LAST_TAB_INDEX",
            payload: newValue
        });
        setValue(newValue);
    };
    if (!authorized)
        return (
            <Box>

                <BusinessAccount/>
            </Box>
        );
    return (
        <Grid container spacing={2} style={{paddingTop: "34px"}}>
            <Grid item xs={12} md={12} lg={11} sx={{paddingRight: "15px"}}>
                <h2>My Spaces</h2>
                <Box sx={{width: "100%"}}>
                    <Box
                        sx={{borderBottom: 1, borderColor: "divider", display: "flex", flexWrap:"wrap"}}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                        >
                            {tabsData.map((tab, index) => (
                                <Tab
                                    label={tab.name}
                                    onClick={() => getStatsData(tab.id)}
                                    key={tab.id}
                                    {...a11yProps(index)}
                                />
                            ))}
                        </Tabs>
                        <Box  sx={{marginLeft: "auto", display:"flex",alignItems:"center",cursor:"pointer"}}>
                            <Box>
                            <Tooltip title="Add Space">
                                    <AddIcon color="primary" onClick={setBusinessForm}/>
                            </Tooltip>
                        </Box>
                        <Box sx={{ml:"10px",cursor:"pointer"}}>
                        <Tooltip title="Space Settings">
                                <SettingsIcon color="primary" onClick={() => navigate(`/space/${tabId}?tab=${value}`)}/>
                                </Tooltip>
                            </Box>
                            </Box>
                    </Box>
                    {tabsData.map((tab, index) => (
                        <TabPanel key={index} value={value} index={index}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                }}
                                className="ratingSection"
                            >

                                <Box sx={{ml: 2}}>
                  <span
                      style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          marginRight: "2px",
                      }}
                  >

                  </span>
                                </Box>
                            </Box>


                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    sm={12}
                                    md={12}
                                    lg={6}
                                    xl={8}
                                    className="margin-bottom-md"
                                >
                                    <Box className="grey-box">
                                        <h3 style={{fontSize: "22px"}}>
                                            Stats
                                        </h3>
                                        <Grid container spacing={2}>
                                            <Grid item sm={12} md={6} lg={6} xl={4}>
                                                <Box className="white-box">
                                                    <h3 className="green-heading">
                                                        {stats?.people_interacted_with_the_system}
                                                    </h3>
                                                    <p className="grey-text">
                                                        People interacted with the system{" "}
                                                    </p>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={12} md={6} lg={6} xl={4}>
                                                <Box className="white-box">
                                                    {stats?.average_stars ? (
                                                        <h3 className="green-heading">
                                                            {stats?.average_stars.toFixed(1)} / 5
                                                        </h3>
                                                    ) : null}
                                                    <p className="grey-text"> Average rank </p>
                                                </Box>
                                                {/*<Box className="white-box">*/}
                                                {/*    {stats?.average_screen_time ? (*/}
                                                {/*        <h3 className="green-heading">*/}
                                                {/*            Coming Soon*/}
                                                {/*            /!*{timeConvert(stats?.average_screen_time)}*!/*/}
                                                {/*        </h3>*/}
                                                {/*    ) : null}*/}
                                                {/*    <p className="grey-text"> Average screen time </p>*/}
                                                {/*</Box>*/}
                                            </Grid>
                                            {/*todo -uncomment it when  comments count ready*/}
                                            {/*<Grid item sm={12} md={6} lg={6} xl={4}>*/}
                                            {/*    <Box className="white-box">*/}
                                            {/*        {stats?.comments_count ? (*/}
                                            {/*            <h3 className="green-heading">*/}
                                            {/*                {stats?.comments_count}*/}
                                            {/*            </h3>*/}
                                            {/*        ) : null}*/}
                                            {/*        <p className="grey-text"> Comments in space </p>*/}
                                            {/*    </Box>*/}
                                            {/*</Grid>*/}
                                            <Grid item sm={12} md={6} lg={6} xl={4}>
                                                <Box className="white-box">
                                                    <h3 className="green-heading">

                                                        {stats?.users_satisfied_with_content}
                                                    </h3>
                                                    <p className="grey-text">
                                                        {" "}
                                                        Users satisfied with content{" "}
                                                    </p>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={12} lg={6} xl={4}>
                                    <Box className="grey-box">
                                        {/*<Box className="white-box-2" style={{marginTop: "24px"}}>*/}
                                        {/*    <StarsIcon*/}
                                        {/*        style={{*/}
                                        {/*            fontSize: "37px",*/}
                                        {/*            color: "#00CA99",*/}
                                        {/*            marginRight: "16px",*/}
                                        {/*            marginTop: "auto",*/}
                                        {/*            marginBottom: "auto",*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*    <h3*/}
                                        {/*        className="green-heading"*/}
                                        {/*        style={{*/}
                                        {/*            marginTop: "auto",*/}
                                        {/*            marginBottom: "auto",*/}
                                        {/*            marginRight: "14px",*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        {stats?.points_earned}*/}
                                        {/*    </h3>*/}
                                        {/*    <p*/}
                                        {/*        className="grey-text"*/}
                                        {/*        style={{marginTop: "auto", marginBottom: "auto"}}*/}
                                        {/*    >*/}
                                        {/*        Points earned*/}
                                        {/*    </p>*/}
                                        {/*</Box>*/}
                                        <Grid container spacing={2}>
                                        <Grid item md={6}>
                                        <Box className="white-box-2">
                                            <StarsIcon
                                                style={{
                                                    fontSize: "37px",
                                                    // color: "#FFCC48",
                                                    color: "rgba(204,236,252,0.95)",
                                                    marginRight: "16px",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                }}
                                            />
                                            <h3
                                                className="green-heading"
                                                style={{
                                                    color: "rgba(94,133,148,0.95)",

                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                    marginRight: "14px",
                                                }}
                                            >
                                                {stats?.pending_rates}
                                            </h3>
                                            <p
                                                className="grey-text"
                                                style={{marginTop: "auto", marginBottom: "auto"}}
                                            >
                                                Pending Reviews Feedbacks
                                            </p>
                                        </Box>
                                        </Grid>
                                        <Grid item md={6}>
                                        <Box className="white-box-2">
                                            <StarsIcon
                                                style={{
                                                    fontSize: "37px",
                                                    color: "rgba(94,133,148,0.95)",
                                                    marginRight: "16px",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                }}
                                            />
                                            <h3
                                                className="green-heading"
                                                style={{
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                    marginRight: "14px",
                                                    color: "rgba(94,133,148,0.95)",

                                                }}
                                            >
                                                {/*{stats?.posts_posted}*/}
                                                {stats?.posts_posted}
                                            </h3>
                                            <p
                                                className="grey-text"
                                                style={{marginTop: "auto", marginBottom: "auto"}}
                                            >
                                                Total Feedbacks
                                            </p>
                                        </Box>
                                        </Grid>
                                        <Grid item md={6}>
                                        <Box className="white-box-2" >
                                            <StarsIcon
                                                style={{
                                                    fontSize: "37px",
                                                    // color: "#FFCC48",
                                                    color: "#00ca99",

                                                    marginRight: "16px",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                }}
                                            />
                                            <h3
                                                className="green-heading"
                                                style={{
                                                    marginTop: "auto",
                                                    color: "rgba(94,133,148,0.95)",

                                                    marginBottom: "auto",
                                                    marginRight: "14px",
                                                }}
                                            >
                                                {stats?.positive_posts_posted}
                                            </h3>
                                            <p
                                                className="grey-text"
                                                style={{marginTop: "auto", marginBottom: "auto"}}
                                            >
                                                Positive Feedbacks
                                            </p>
                                        </Box>
                                        </Grid>
                                        <Grid item md={6}>
                                        <Box className="white-box-2">
                                            <StarsIcon
                                                style={{
                                                    fontSize: "37px",
                                                    // color: "#FFCC48",
                                                    color: "#ea7070",
                                                    marginRight: "16px",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                }}
                                            />
                                            <h3
                                                className="green-heading"
                                                style={{
                                                    color: "rgba(94,133,148,0.95)",

                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                    marginRight: "14px",
                                                }}
                                            >
                                                {stats?.negative_posts_posted}
                                            </h3>
                                            <p
                                                className="grey-text"
                                                style={{marginTop: "auto", marginBottom: "auto"}}
                                            >
                                                Negative Feedbacks
                                            </p>
                                        </Box>
                                        </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                {/*<Grid item xs={4}>*/}
                                {/*    <Button className="connectButton" variant="contained">*/}
                                {/*        INVITE USERS*/}
                                {/*    </Button>*/}
                                {/*</Grid>*/}
                            </Grid>
                        </TabPanel>
                    ))}
                </Box>
                <hr
                    style={{
                        borderTop: "1px solid #E0E0E0",
                        marginTop: "30px",
                        marginBottom: "30px",
                    }}
                />
            </Grid>

            {/*<h3 style={{fontSize: "22px"}}>Top rated tags and keywords</h3>*/}
            {/*<label style={{marginLeft:'10px',color:'#128d6f'}}>Coming Soon</label>*/}
            {/*<ChipList list={stats?.top_tags?.length ? stats.top_tags : []}/>*/}
            <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={6}>
                    {/*<h3 style={{fontSize: "22px"}}>Recent rated</h3>*/}
                    <h3 style={{fontSize: "22px"}}>Recent Feedbacks</h3>
                    <Box
                        style={{
                            height: data.length > 0 ? "500px" : "auto",
                            overflowY: "auto",
                        }}
                    >
                        {!data.recent_feedbacks.length && <div style={{marginBottom: "30px"}}>
                            The space dont have any feedback
                        </div>}
                        {data.recent_feedbacks.map((m, index) => (
                            <HomeCardItem
                                isBookmark={true}
                                isFeedback={true}
                                key={index}
                                data={m}
                                searhTags={searchTags}
                                applyMargin={false}
                                onRateSuccessfully={onRateSuccessfully}
                            />
                        ))}
                    </Box>
                </Grid>
                {(featuresActivity.right_rate_information || featuresActivity.rate_paragraph_button || featuresActivity.media_rate) &&
                <Grid item sm={12} md={12} lg={6}>
                    <h3 style={{fontSize: "22px"}}>Top Feedbacked </h3>
                    <Box
                        style={{
                            height: data.length > 0 ? "500px" : "auto",
                            overflowY: "auto",
                        }}
                    >
                        {!data.rates.length && <div style={{marginBottom: "30px"}}>
                            The space dont have any rate
                        </div>}
                        {data.rates.map((m, index) => (
                            <HomeCardItem
                                isSpace={true}
                                isBookmark={true}
                                key={index}
                                data={m}
                                searhTags={searchTags}
                                applyMargin={false}
                                onRateSuccessfully={onRateSuccessfully}
                            />
                        ))}
                    </Box>
                </Grid>}

            </Grid>

        </Grid>
    );
};
export default MySpaces;
