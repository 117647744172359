import {
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Rating,
  Typography,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import React from "react";
import "../style.css";
import CloseIcon from "@mui/icons-material/Close";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: "100%",
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light" ? theme.palette.primary : "#308fe8",
  },
}));

const StatisticsDialog = ({ handleClose, open, total, data }) => {
  return (
    <Dialog
      classes={{
        paperScrollPaper: {
          padding: "20px !important",
          width: "390px !important",
          borderRadius: "20px !important",
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <DialogTitle className="dialogHeader">
        Statistics{" "}
        <IconButton
          onClick={handleClose}
          style={{ left: "32%" }}
          aria-label="delete"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {data && (
        <List className="dialogList" sx={{ pt: 0, width: "370px" }}>
          <ListItem>
            <Typography className="rateNumber" variant="h5">
              5
            </Typography>
            <Rating
              style={{ color: "rgba(255, 204, 72, 1)" }}
              className="ratingStats"
              name="customized-10"
              defaultValue={1}
              max={1}
            />
            <BorderLinearProgress variant="determinate" value={50} />{" "}
            <span className="percentStat">
              {Math.round((data["5"] * 100) / total)}%
            </span>
          </ListItem>
          <ListItem>
            <Typography className="rateNumber" variant="h5">
              4
            </Typography>
            <Rating
              style={{ color: "rgba(255, 204, 72, 1)" }}
              className="ratingStats"
              name="customized-10"
              defaultValue={1}
              max={1}
            />
            <BorderLinearProgress
              color="primary"
              variant="determinate"
              value={50}
            />{" "}
            <span className="percentStat">
              {Math.round((data["4"] * 100) / total)}%
            </span>
          </ListItem>
          <ListItem>
            <Typography className="rateNumber" variant="h5">
              3
            </Typography>
            <Rating
              style={{ color: "rgba(255, 204, 72, 1)" }}
              className="ratingStats"
              name="customized-10"
              defaultValue={1}
              max={1}
            />
            <BorderLinearProgress variant="determinate" value={50} />{" "}
            <span className="percentStat">
              {Math.round((data["3"] * 100) / total)}%
            </span>
          </ListItem>
          <ListItem>
            <Typography className="rateNumber" variant="h5">
              2
            </Typography>
            <Rating
              style={{ color: "rgba(255, 204, 72, 1)" }}
              className="ratingStats"
              name="customized-10"
              defaultValue={1}
              max={1}
            />
            <BorderLinearProgress variant="determinate" value={50} />
            <span className="percentStat">
              {Math.round((data["2"] * 100) / total)}%
            </span>
          </ListItem>
          <ListItem>
            <Typography className="rateNumber" variant="h5">
              1
            </Typography>
            <Rating
              style={{ color: "rgba(255, 204, 72, 1)" }}
              className="ratingStats"
              name="customized-10"
              defaultValue={1}
              max={1}
            />
            <BorderLinearProgress variant="determinate" value={50} />
            <span className="percentStat">
              {Math.round((data["1"] * 100) / total)}%
            </span>
          </ListItem>
        </List>
      )}
    </Dialog>
  );
};

export default StatisticsDialog;
