import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "./Components/Header";
import Router from "./Components/Router";
// import Sidebar from "./Components/Sidebar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ScrollToTop from "./Components/common/ScrollToTop";
import { API_URL } from "./consts";
import axios from "axios";

const theme = createTheme({
  palette: {
    primary: {
      light: "#fff",
      main: "rgb(0,202,153)",
      dark: "rgb(0,202,153)",
    },
    secondary: {
      main: "rgb(0,202,153)",
    },
  },
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          color: "rgb(0,202,153) !important",
        },
      },
    },
  },
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const App = () => {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState(false);
  const authorized = useSelector((state) => state.authorized);
  const popupTxt = useSelector((state) => state.popupTxt);
  const [isLocationAuth, setIsLocationAuth] = useState(false);
  const state = {
    vertical: "top",
    horizontal: "right",
  };

  useEffect(() => {
    let token = localStorage.getItem("user_token")
    if (token) {
      dispatch({
        type: "SET_TOKEN",
        payload: token,
      })
      let url = API_URL + `/profile`;

      console.log(token)
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch({
            type: "SET_USER_DATA",
            payload: { ...res.data.profile },
          });
          // window.location.reload()
        })
        .catch((error) => {
          dispatch({
            type: "SET_UNAUTHORIZED",
          });
        })
      dispatch({
        type: "SET_USER_DATA",
        payload: token,
      });
      dispatch({
        type: "SET_AUTHORIZED",
        user: null,
      });
    } else {
      dispatch({
        type: "SET_UNAUTHORIZED",
      });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Box className={!isLocationAuth ? "d-flex" : ""}>
          {!isLocationAuth ? (
            <>
              <Snackbar
                open={!!popupTxt.text}
                anchorOrigin={state}
                key={state.vertical + state.horizontal}
              >
                <Alert severity={popupTxt.type} sx={{ width: "100%" }}>
                  {popupTxt.text}
                </Alert>
              </Snackbar>
              <Header setProfile={setProfile} />
            </>
          ) : null}
          <Router
            authorized={!isLocationAuth}
            user={authorized}
            setIsLocationAuth={setIsLocationAuth}
          />
          <ScrollToTop />
        </Box>

      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
