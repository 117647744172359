import ChatIcon from "@mui/icons-material/Chat";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Avatar, Grid, IconButton, Typography ,Chip} from "@mui/material";
import { Box } from "@mui/system";
import Link from "@mui/material/Link";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

const MarksComments = ({ data }) => {
  const navigate = useNavigate();
  const [like, setLike] = useState(false);
  const [hideMore, setHideMore] = useState(true);

  const handleClickLike = () => {
    setLike(!like);
  };

  return (
    <Grid container className="commentItem2" style={{ position: "relative" }}>
      <Grid item xs={12} sm={8}>
        <Grid item xs={12}>
          <Box className="profileDiv profileDiv2">
            <Box onClick={()=>navigate(`/user/${data.user_id}`)}>
              <Avatar
                className="profilePic"
                alt="Profile Picture"
                src={data.profile_image_url}
              />
            </Box>
            <Box className="profileDesc">
              <Box sx={{ display: "flex" }} onClick={()=>navigate(`/user/${data.user_id}`)}>
                <Box className="userName">{data.username} </Box>
              </Box>
              <Box
                sx={{
                  color: "rgba(38, 38, 38, 0.5)",
                  fontSize: "12px",
                  marginBottom: "0px",
                }}
              >
                {new Date(data.create_time).toLocaleDateString()}
              </Box>
              <Box
                sx={{
                  width: 200,
                  display: "flex",
                  alignItems: "center",
                }}
              ></Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className="desc-text"
          variant="body2"
          color="text.primary"
          sx={{ marginTop: "13px" }}
        >
          {data?.comment?.length > 300 ? (
            hideMore ? (
              <p>
                {data?.comment?.slice(0, 300)}...
                <Link
                  sx={{
                    cursor: "pointer",
                    color: "#128d6f",
                    fontWeight: "700",
                    textDecorationColor: "#128d6f",
                  }}
                  onClick={(e) => setHideMore(false)}
                >
                  Read More
                </Link>
              </p>
            ) : (
              <>
                {" "}
                <p>
                  {" "}
                  {data?.comment}{" "}
                  <Link
                    sx={{
                      cursor: "pointer",
                      fontWeight: "700",
                      color: "#128d6f",
                      textDecorationColor: "#128d6f",
                    }}
                    onClick={(e) => setHideMore(true)}
                  >
                    Show Less
                  </Link>
                </p>
              </>
            )
          ) : (
            <>{data?.comment}</>
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{marginTop:"11px"}}>
      {/* <Chip
            className="viewCount"
            icon={
              <FavoriteIcon
                className={`iconColor likeIcon ${like ? " liked" : ""}`}
                color="primary"
              />
            }
            onClick={() => handleClickLike(true)}
            label={data.likes}
          /> */}

        <IconButton aria-label="delete" className="iconActions">
          <ChatIcon className="iconColor" color="primary" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default MarksComments;
