import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid, TextField } from "@mui/material";
import { API_URL } from "../../consts";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

const ChangePassword = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(false);
  const handleFormChange = (e) => {
    setSubmit(false);
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const validatePassword = (password) => {
    if (/^(?=.*?[#?!@$%^&*-]).{8,}$/.test(password)) {
      return true;
    } else {
      return false;
    }
  };
  const handleFormSubmit = () => {
    setSubmit(true);
    if (
      form.old_password &&
      form.new_password &&
      form.confirm_new_password &&
      form.new_password === form.confirm_new_password &&
      validatePassword(form.new_password)
    ) {
      let { old_password, new_password } = form;
      let url = API_URL + `/security/reset-password`;
      axios
        .post(
          url,
          {
            old_password,
            new_password,
          }
        )
        .then((res) => {
          setError(false);
          dispatch({
            type: "SET_UNAUTHORIZED",
          });
          navigate("/login");
        })
        .catch((err) => {
          setError(true);
        });
    }
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style} className="signup-modal">
          <Typography id="transition-modal-title" variant="h6" component="h2">
            <CloseIcon
              className="cross-icon"
              onClick={() => {
                handleClose();
                setSubmit(false);
                setError(false);
              }}
            />
            <div className="card-heading mb-81"></div>
          </Typography>
          <Box id="transition-modal-description">
            <Typography
              id="transition-modal-title"
              variant="h2"
              component="h2"
              sx={{ fontSize: "20px", textAlign: "center", fontWeight: "700" }}
            >
              Change Password
            </Typography>
            {error ? (
              <Alert severity="error" sx={{ marginTop: "10px" }}>
                <AlertTitle>Error</AlertTitle>
                Unable to Complete the Process, Please Check you input Valid
                Details.
              </Alert>
            ) : null}
            <TextField
              name={"old_password"}
              hiddenLabel
              type="Password"
              fullWidth
              className="loginTextfields"
              id="standard-basic"
              onChange={handleFormChange}
              label={"Current Password"}
              variant="standard"
              error={submit && form.old_password === "" ? true : false}
              helperText={
                submit && form.old_password === "" ? "Field is Required" : ""
              }
              sx={{ marginTop: "20px" }}
            />
            <TextField
              name={"new_password"}
              hiddenLabel
              type="Password"
              fullWidth
              className="loginTextfields"
              id="standard-basic"
              onChange={handleFormChange}
              label={"New Password"}
              variant="standard"
              error={
                submit &&
                (form.new_password === "" ||
                  !validatePassword(form.new_password))
                  ? true
                  : false
              }
              helperText={
                submit && form.new_password === ""
                  ? "Field is Required"
                  : submit && !validatePassword(form.new_password)
                  ? "Atleast 8 charactes required with one special char"
                  : ""
              }
              sx={{ marginTop: "20px" }}
            />
            <TextField
              name={"confirm_new_password"}
              hiddenLabel
              type="Password"
              fullWidth
              className="loginTextfields"
              id="standard-basic"
              onChange={handleFormChange}
              label={"Confirm New Password"}
              variant="standard"
              error={
                submit &&
                (form.confirm_new_password === "" ||
                  form.confirm_new_password !== form.new_password)
                  ? true
                  : false
              }
              helperText={
                submit && form.confirm_new_password === ""
                  ? "Field is Required"
                  : submit && form.confirm_new_password !== form.new_password
                  ? "Password fields don't match with each other"
                  : ""
              }
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            />
            <Grid container sx={{ margin: "0 auto" }}>
              <Grid item sx={{ margin: "0 auto" }}>
                <Button
                  className="connectButton"
                  onClick={handleFormSubmit}
                  variant="contained"
                >
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ChangePassword;
