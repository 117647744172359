import {
  Grid,
  IconButton,
  Button,
  Input,
  Typography,
  Autocomplete,
  TextField,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ChipList from "../common/ChipList";

const EditableChipField = ({
  label,
  value,
  onChange,
  handleFormChange,
  fieldName,
  updateField,
  boxWidth,
}) => {
  const [receivers, setReceivers] = React.useState([]);
  const [inputValue, setInputValue] = useState([]);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(false);
  React.useEffect(() => {
    if (value.length > 0) {
      setReceivers([...value]);
      setInputValue([...value]);
    }
  }, [value]);

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  return (
    <Grid item xs={boxWidth} className="editable">
      {label ? (
        <Typography className="profileItemHeader">{label}</Typography>
      ) : null}
      <Typography
        className={classNames(
          boxWidth !== "12" ? "paddingStyle" : "",
          "d-flex"
        )}
      >
        {edit ? (
          <Box sx={{ display: "block", width: "100%" }}>
            <Autocomplete
              multiple
              onChange={(e, newval, reason) => {
                setReceivers(newval);
                setError(false);
              }}
              id="tags-filled"
              options={[]}
              value={receivers}
              name={fieldName}
              sx={{
                width: boxWidth !== "12" ? "100%" : "auto",
                minWidth: boxWidth !== "12" ? "auto" : "100%",
              }}
              freeSolo
              clearOnBlur={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  error={error}
                  size="smaill"
                  helperText={error ? "Field is Required" : ""}
                  onBlur={(e) => {
                    if (e.target.value !== "") {
                      setReceivers(receivers.concat(e.target.value));
                    }
                  }}
                />
              )}
            />
            <Typography
              paragraph
              sx={{ fontSize: "12px" }}
              className="textMuted"
            >
              Each Tag must contain alteast Two Characters.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}>
            <ChipList list={inputValue?.length > 0 ? inputValue : value} />
          </Box>
        )}

        {edit ? (
          <Button
            color="primary"
            disabled={receivers.some((tag) => tag.length < 2)}
            onClick={() => {
              if (edit && receivers.length === 0) {
                setError(true);
              } else if (edit && inputValue) {
                if (updateField) {
                  updateField(fieldName,receivers);
                }
                setInputValue(receivers);
                handleFormChange(receivers);
                setEdit(!edit);
                setError(false);
              } else {
                setEdit(!edit);
              }
            }}
          >
            <SaveIcon color="primary" />
          </Button>
        ) : (
          // eslint-disable-next-line react/jsx-no-undef
          <Button
            color="primary"
            style={{ borderRadius: "0" }}
            onClick={() => setEdit(!edit)}
          >
            <EditIcon color="primary" />
          </Button>
        )}
      </Typography>
    </Grid>
  );
};

export default EditableChipField;
