import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
    Button,
    Grid,
    IconButton,
    TextField,
    Typography,
    Box,
    Link,
    Switch, Autocomplete, Chip, FormControl, InputLabel, Input, InputAdornment
} from "@mui/material";
import HighlightOff from '@mui/icons-material/HighlightOff';
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React, {useEffect, useRef, useState} from "react";
import EditableFields from "../Profile/EditableFields";
import EditableChipField from "../Profile/EditableChipField";
import "../style.css";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import axios from "axios";
import {API_URL} from "../../consts";
import {useParams} from "react-router";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {popupTimer} from "../../consts";
import ConfirmationDialog from "../common/ConfirmationDialog";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import ChatIcon from "@mui/icons-material/Chat";
import SearchIcon from "@mui/icons-material/Search";
import {pink} from "@mui/material/colors";
import CommentItem from "../common/CommentItem";
import Tooltip from '@mui/material/Tooltip';
import EditableRadioField from "../Profile/EditableRadioField";
import CollaboratorsDialog from "../common/CollaboratorsDialog";

const dialogStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '600px',
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
};

const WebsiteDetails = () => {
    let {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [deleteOpen, setDeleteWebsiteDialog] = useState(false);
    const [tab, setTab] = useState(0);
    const [collaboratorsOpen, setCollaboratorsDialog] = useState(false);
    const [form, setForm] = useState({
        name: "",
        url: "",
        id: "",
        mode: "",
        tags: [],
        description: "",
        collaborators: [],
        features_activity: {
            login_with_website_account: false,
            rate_paragraph_button: false,
            media_rate: false,
            right_search_button: false,
            right_rate_information: false,
            show_popup: false,
            show_recommendations: false
        },
    });
    const [data, setData] = useState({});
    useEffect(() => {
        getWebsiteData();
        if (window.location.search) {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let tab = params.get("tab");
            setTab(tab);
        }
    }, []);
    const updateFeatureActivity = (featureName, status) => {
        let url = API_URL + `/tenants/websites/${id}`;
        let newForm = {...form}
        newForm['features_activity'][featureName] = status
        setForm({...newForm});

        let formData = {...newForm};
        axios
            .put(url, formData)
            .then((res) => {

            });
    }
    const updateCollaborators = (collaborators) => {
        let url = API_URL + `/tenants/websites/${id}`;
        let newForm = {...form}
        newForm['collaborators'] = collaborators

        let formData = {...newForm};
        axios
            .put(url, formData)
            .then((res) => {
                getWebsiteData()
                setCollaboratorEmail('')


            }).catch((err) => {
            dispatch({
                type: "SET_POPUP",
                payload: {
                    text: err.response.data.error
                        ? err.response.data.error
                        : "Something Went Wrong. Please Try Again Later",
                    type: "error",
                },
            });
            setTimeout(() => {
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: "",
                        type: "",
                    },
                });
            }, popupTimer);
        });
    }
    const deleteWebsite = () => {
        let url = API_URL + `/tenants/websites/${id}`;
        axios
            .delete(url)
            .then((res) => {
                setDeleteWebsiteDialog(false);
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: "Website Deleted Successfully",
                        type: "success",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
                navigate(`/my-websites`);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });
    };
    const handleFormChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value});
    };
    const setTags = (value) => {
        setForm({...form, tags: value});
    };
    const getWebsiteData = () => {
        let url = API_URL + `/tenants/websites/${id}`;
        axios
            .get(url)
            .then((res) => {
                setData(res.data.website);
                setForm({
                    collaborators: res.data.website.collaborators,
                    name: res.data.website.name,
                    id: res.data.website.id,
                    secret_key: res.data.website.secret_key,
                    url: res.data.website.url,
                    mode: res.data.website.mode,
                    tags: res.data.website.tags.length > 0 ? res.data.website.tags : [],
                    description: res.data.website.description,
                    features_activity: res.data.website.features_activity
                });
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
            });
    };

    const [collaboratorEmail, setCollaboratorEmail] = useState("");

    const updateWebsite = (fieldName, fieldData) => {
        let url = API_URL + `/tenants/websites/${id}`;
        let formData = {...form, [fieldName]: fieldData}
        axios
            .put(url, formData)
            .then((res) => {
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: "Website Updated Successfully",
                        type: "success",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, popupTimer);
                if (fieldName !== 'mode') navigate(`/my-websites?id=${id}&tab=${tab}`);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    dispatch({
                        type: "SET_UNAUTHORIZED",
                    });
                    return;
                }
                dispatch({
                    type: "SET_POPUP",
                    payload: {
                        text: err.response.data.error
                            ? err.response.data.error
                            : "Something Went Wrong. Please Try Again Later",
                        type: "error",
                    },
                });
                setTimeout(() => {
                    dispatch({
                        type: "SET_POPUP",
                        payload: {
                            text: "",
                            type: "",
                        },
                    });
                }, 3000);
            });
    };
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setForm({...form, image: URL.createObjectURL(event.target.files[0])});
        }
    };
    const addCollaborator = (collaboratorEmail) => {
        let collaborators = form['collaborators'].slice()
        let userExist = collaborators.find((c) => c.email === collaboratorEmail)
        if (userExist) {
            return
        }
        collaborators.push({'name': '', 'email': collaboratorEmail})

        updateCollaborators(collaborators)

    };
    const deleteCollaborators = (email) => {
        let collaborators = form['collaborators'].slice()
        let indexInArray = collaborators.findIndex((c) => c.email === email)
        if (indexInArray < 0) {
            return
        }
        collaborators.splice(indexInArray, 1)
        updateCollaborators(collaborators)

    };
    let headerKey = "<meta name='datarate-api-key' data-api-key=" + form.id + '>'

    let baseObject = "<div id=\"datarate\"></div>"
    return (
        <Grid item xs={12} md={12} lg={11} style={{paddingTop: "34px"}}>
            <h2>{form?.name}</h2>
            {/* <input
                type="file"
                name="filename"
                accept="image/gif, image/jpeg, image/png"
                style={{display: "none"}}
                ref={inputElement}
                onChange={onImageChange}
            />

            <Badge
                overlap="circular"
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                badgeContent={
                    <IconButton
                        onClick={() => {
                            inputElement.current.click();
                        }}
                        aria-label="delete"
                    >
                        <AddCircleIcon color="primary"/>
                    </IconButton>
                }
            >
                <Avatar
                    className="profileAvatar"
                    alt="Travis Howard"
                    src={form.image}
                />
            </Badge> */}
            {/* <BusinessAccountInputField
        label="Company Name"
        name="Company_name"
        value={form.Company_name}
        onChange={handleFormChange}
      /> */}

            <EditableFields
                label={"Website Name"}
                value={form.name}
                fieldName={"name"}
                handleFormChange={handleFormChange}
                updateField={updateWebsite}
            />
            <EditableFields
                label={"Website Link"}
                value={form.url}
                fieldName={"url"}
                handleFormChange={handleFormChange}
                updateField={updateWebsite}
            />
            <EditableFields
                label={"Description"}
                value={data?.description}
                textBox={true}
                fieldName={"description"}
                handleFormChange={handleFormChange}
                updateField={updateWebsite}
            />

            <EditableChipField
                label={"Website Tags"}
                value={form.tags}
                fieldName={"tags"}
                handleFormChange={setTags}
                updateField={updateWebsite}
                boxWidth={"8"}
            />
            <EditableRadioField
                label={"Mode"}
                value={form.mode}
                fieldName={"mode"}
                handleFormChange={handleFormChange}
                updateField={updateWebsite}
                defaultLabels={[{value: "rate", label: "Rate"}, {value: "feedback", label: "Feedback"}]}
            />
            <Typography className="profileItemHeader">Features activity</Typography>
            <div style={{display: 'flex'}}>
                {form.mode === 'rate' ?
                    <>
                        <div style={{marginLeft: '20px'}}>
                            <div>
                                <label> Content rate</label>
                                <Switch checked={form.features_activity.rate_paragraph_button} onChange={() => {
                                    updateFeatureActivity('rate_paragraph_button', !form.features_activity.rate_paragraph_button)

                                }}/>
                            </div>
                            <div>
                                <label> Media rate</label>

                                <Switch checked={form.features_activity.media_rate} onChange={() => {
                                    updateFeatureActivity('media_rate', !form.features_activity.media_rate)
                                }}/>
                            </div>
                        </div>

                        <div style={{marginLeft: '20px'}}>
                            <div>
                                <label> Popups</label>
                                <Switch checked={form.features_activity.show_popup} onChange={() => {
                                    updateFeatureActivity('show_popup', !form.features_activity.show_popup)

                                }}/>
                            </div>
                            <div>
                                <div style={{float: 'right'}}>
                                    <label> Recommendations</label>

                                    <Switch checked={form.features_activity.show_recommendations} onChange={() => {
                                        updateFeatureActivity('show_recommendations', !form.features_activity.show_recommendations)

                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div style={{marginLeft: '20px'}}>
                            <div>
                                <label> Page search</label>
                                <Switch checked={form.features_activity.right_search_button} onChange={() => {
                                    updateFeatureActivity('right_search_button', !form.features_activity.right_search_button)
                                }}/>
                            </div>

                            <div>
                                <label> Page rate</label>
                                <Switch checked={form.features_activity.right_rate_information} onChange={() => {
                                    updateFeatureActivity('right_rate_information', !form.features_activity.right_rate_information)

                                }}/>
                            </div>

                        </div>
                    </>
                    : null}
                {form.mode === 'feedback' ?
                    <>
                        <div style={{marginLeft: '20px'}}>

                            <div>
                                <label> Feedback</label>
                                <Switch checked={form.features_activity.feedback} onChange={() => {
                                    updateFeatureActivity('feedback', !form.features_activity.feedback)

                                }}/>
                            </div>
                            <div>
                                <label> Login to data rate</label>
                                <Switch checked={form.features_activity.login} onChange={() => {
                                    updateFeatureActivity('login', !form.features_activity.login)

                                }}/>
                            </div>
                            <div>
                                <label> Login with user website account </label>
                                <Switch checked={form.features_activity.login_with_website_account} onChange={() => {
                                    updateFeatureActivity('login_with_website_account', !form.features_activity.login_with_website_account)

                                }}/>
                            </div>


                        </div>
                        <div style={{marginLeft: '20px'}}>
                            <div>
                                <label> User can track feedback</label>
                                <Switch checked={form.features_activity.user_track_feedback} onChange={() => {
                                    updateFeatureActivity('user_track_feedback', !form.features_activity.user_track_feedback)

                                }}/>
                            </div>
                            <div>
                                <label> Email notifications</label>
                                <Tooltip
                                    title={'Receive new alerts via email for each new rate or feedback on your website.'}>

                                    <Switch checked={form.features_activity.email_notifications} onChange={() => {
                                        updateFeatureActivity('email_notifications', !form.features_activity.email_notifications)

                                    }}/>
                                </Tooltip>

                            </div>
                        </div>
                    </> : null}

            </div>

            {/*<EditableFields*/}
            {/*    label={"Access token name from local storage(if is dictionary split keys with '__' ) "}*/}
            {/*    value={data?.token_name}*/}
            {/*    textBox={true}*/}
            {/*    fieldName={"token_name"}*/}
            {/*    handleFormChange={handleFormChange}*/}
            {/*    updateField={updateWebsite}*/}
            {/*/>*/}
            {/*<EditableFields*/}
            {/*    label={"User info URL"}*/}
            {/*    value={data?.user_info_url}*/}
            {/*    textBox={true}*/}
            {/*    fieldName={"user_info_url"}*/}
            {/*    handleFormChange={handleFormChange}*/}
            {/*    updateField={updateWebsite}*/}
            {/*/>*/}
            {/*<EditableFields*/}
            {/*    label={"Username field name"}*/}
            {/*    value={data?.user_name_field_name}*/}
            {/*    textBox={true}*/}
            {/*    fieldName={"user_info_url"}*/}
            {/*    handleFormChange={handleFormChange}*/}
            {/*    updateField={updateWebsite}*/}
            {/*/>*/}
            {/*<EditableFields*/}
            {/*    label={"User ID field name"}*/}
            {/*    value={data?.user_id_field_name}*/}
            {/*    textBox={true}*/}
            {/*    fieldName={"user_info_url"}*/}
            {/*    handleFormChange={handleFormChange}*/}
            {/*    updateField={updateWebsite}*/}
            {/*/>*/}
            {/*<EditableFields*/}
            {/*    label={"Email field name"}*/}
            {/*    value={data?.email_field_name}*/}
            {/*    textBox={true}*/}
            {/*    fieldName={"user_info_url"}*/}
            {/*    handleFormChange={handleFormChange}*/}
            {/*    updateField={updateWebsite}*/}
            {/*/>*/}


            <div style={{display: 'flex'}}>
                <div>
                    <Button
                        onClick={() => navigate(`/my-websites?id=${id}&tab=${tab}`)}
                        className="connectButton"
                        variant="contained"
                    >
                        Stats & Analytics
                    </Button>
                </div>
                <div style={{marginLeft: '20px'}}>
                    <Button
                        onClick={() => setCollaboratorsDialog(true)}
                        className="connectButton"
                        variant="contained"
                    >
                        collaborators
                    </Button>
                </div>
            </div>
            <h2 style={{marginTop: '20px'}}>How to connect Datarate plugin to your website</h2>
            <label> Connect website to datarate is very easy, you just need to add the following segments to your
                website</label>

            <Typography className="profileItemHeader" sx={{padding: "10px 0px"}}>
                Website headers
            </Typography>
            <TextareaAutosize
                aria-label="minimum height"
                minRows={6}
                fullWidth
                readOnly={true}
                value={headerKey + '\n' +
                "    <link rel=\"stylesheet\" href=\"https://d2kzr9hca36gxq.cloudfront.net/prod/dist/css/main.css\">\n" +
                "    <link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css\">\n" +
                "    <link href=\"https://d2kzr9hca36gxq.cloudfront.net/prod/dist/app.datarate.css\" rel=\"preload\" as=\"style\">\n" +
                "    <link href=\"https://d2kzr9hca36gxq.cloudfront.net/prod/dist/app.datarate.js\" rel=\"preload\" as=\"script\">\n" +
                "    <link href=\"https://d2kzr9hca36gxq.cloudfront.net/prod/dist/chunk-vendors.datarate.css\" rel=\"preload\" as=\"style\">\n" +
                "    <link href=\"https://d2kzr9hca36gxq.cloudfront.net/prod/dist/chunk-vendors.datarate.js\" rel=\"preload\" as=\"script\">\n" +
                "    <link href=\"https://d2kzr9hca36gxq.cloudfront.net/prod/dist/chunk-vendors.datarate.css\" rel=\"stylesheet\">\n" +
                "    <link href=\"https://d2kzr9hca36gxq.cloudfront.net/prod/dist/app.datarate.css\" rel=\"stylesheet\">"
                }
                placeholder=""
                className="textarea_business_2"
            />
            <Typography className="profileItemHeader" sx={{padding: "20px 0px"}}>
                Website body- start
            </Typography>
            <TextareaAutosize
                aria-label="minimum height"
                minRows={6}
                fullWidth
                readOnly={true}
                value={
                    baseObject
                }
                placeholder=""
                className="textarea_business_2"
            />
            <Typography className="profileItemHeader" sx={{padding: "20px 0px"}}>
                Website body- end
            </Typography>
            <TextareaAutosize
                aria-label="minimum height"
                minRows={6}
                fullWidth
                readOnly={true}
                value={
                    "<script src=\"https://d2kzr9hca36gxq.cloudfront.net/prod/dist/chunk-vendors.datarate.js\"></script>\n" +
                    "<script src=\"https://d2kzr9hca36gxq.cloudfront.net/prod/dist/app.datarate.js\"></script>"
                }
                placeholder=""
                className="textarea_business_2"
            />
            <div>

                <h1> How to integrate with your user system</h1>
                <label style={{maxWidth: '100px'}}> if you want the Datarate system will determine your user,
                    You need to create a JWT token that included the email(payload) and API key provided (api_key) in
                    header.<br/>
                    The token should be generated using the given access key.<br/>
                    the token need to be stored in local storage in name "datarate_token"</label>


                {/*<EditableRadioField*/}
                {/*    label={"Authorization source"}*/}
                {/*    value={form.mode}*/}
                {/*    fieldName={"mode"}*/}
                {/*    handleFormChange={handleFormChange}*/}
                {/*    updateField={updateWebsite}*/}
                {/*    defaultLabels={[{value:"cookies", label:"Cookies"},{value:"local_storage", label:"Local storage"}]}*/}
                {/*/>*/}
                <Typography className="profileItemHeader">API key</Typography>
                <Typography className="paddingStyle d-flex">
                    <TextField
                        value={form.id}
                        name={'api_key'}
                        multiline={false}
                        sx={{width: "30%"}}
                        variant="standard"
                    />
                </Typography>
                <Typography className="profileItemHeader">Secret key</Typography>
                <Typography className="paddingStyle d-flex">
                    <TextField
                        value={form.secret_key}
                        name={'secret key'}
                        multiline={false}
                        sx={{width: "30%"}}
                        variant="standard"
                    />
                </Typography>
                <br/>

                <br/>
            </div>
            <Box sx={{display: "flex", alignItems: "center", marginTop: '10px'}}>
                <Link
                    text
                    color="error"
                    sx={{mr: 2, cursor: "pointer"}}
                    onClick={() => setDeleteWebsiteDialog(true)}
                >
                    DELETE WEBSITE
                </Link>

            </Box>
            <ConfirmationDialog
                open={deleteOpen}
                onClose={() => setDeleteWebsiteDialog(false)}
                onConfirm={() => deleteWebsite()}
                title={"Delete Website"}
                cancelButtonString={"Cancel"}
                confirmButtonString={"Delete"}
                message={"Are you sure you want to Delete this Website?"}
            />
            <CollaboratorsDialog open={collaboratorsOpen}
                                 onClose={() => {
                                     setCollaboratorsDialog(false)
                                 }}
                                 onAddCollaboratorClick={addCollaborator}
                                 onDeleteCollaboratorClick={deleteCollaborators}
                                 collaborators={form.collaborators?form.collaborators:[]}
            />

        </Grid>
    );
};

export default WebsiteDetails;
