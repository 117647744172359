import {
    Grid,
    IconButton,
    Button,
    Input,
    Typography,
    TextField,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import EditIcon from "@mui/icons-material/Edit";
  import SaveIcon from "@mui/icons-material/Save";
  import { validateWebsite } from "../../consts";
  import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
  import "../style.css";
  
  const EditableRadioField = ({
    label,
    value,
    onChange,
    textBox,
    handleFormChange,
    fieldName,
    updateField,
    defaultLabels
  }) => {
    const [inputValue, setInputValue] = useState(value);
    const [error, setError] = useState("");
    useEffect(() => {
      setInputValue(value);
    }, [value]);
  
    return (
      <Grid item xs={8} className="editable">
        <Typography className="profileItemHeader">{label}</Typography>
        <Typography className="paddingStyle d-flex">
              <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="mode"
              value={inputValue}
              sx={{display:"block"}}
              onChange={(e)=>{handleFormChange(e);updateField(fieldName,e.target.value)}}
            >
              {defaultLabels?.map((item, index)=>(
              <FormControlLabel value={item.value} control={<Radio />} label={item.label} key={index} />

              ))}
            </RadioGroup>
        </Typography>
      </Grid>
    );
  };
  
  export default EditableRadioField;
  