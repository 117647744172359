import { Button } from "@mui/material";
import React from "react";

const BusinessAccountMainPageContent = ({ openBusinessForm }) => {
  return (
    <>
      <div className="subHeader bussinessHeader">Connect your website to Data Rate</div>
      <div>
        Become an ambassador of advanced web search. Get more visitors and
        increase customers engagement by embedding Data Rate to your website.
      </div>
      <Button
        className="connectButton"
        variant="contained"
        onClick={openBusinessForm}
      >
        Connect WEBSITE
      </Button>
    </>
  );
};

export default BusinessAccountMainPageContent;
