import { useState } from 'react';
import { Typography, Link } from '@mui/material';

const Description = ({ content,onClickShowMore }) => {
    const [hideMore, setHideMore] = useState(true);

    return (
        <Typography className="desc-text" variant="body2" color="text.primary">
            <div
                style={{
                    minHeight: '70px', // Adjust this height as needed

                    maxHeight: '70px', // Adjust this height as needed
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: hideMore ? 5 : 'none', // Adjust the number of lines as needed
                    textOverflow: hideMore ? 'ellipsis' : 'clip',
                }}
            >
                {content}
            </div>
            <Link
                sx={{
                    cursor: 'pointer',
                    color: '#128d6f',
                    fontWeight: '700',
                    textDecorationColor: '#128d6f',
                }}
                onClick={() => onClickShowMore()}
            >
                 Show More
            </Link>
        </Typography>
    );
};

export default Description;
