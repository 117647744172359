import CreateIcon from "@mui/icons-material/Create";
import { Grid, IconButton, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import React, { useRef } from "react";
import ChipList from "../common/ChipList";
import EditableFields from "../Profile/EditableFields";
import "../style.css";

const ReviewForm = ({ form, setForm }) => {
  const handleSearchTagDelete = (index) => {
    let newSearcgTags = form.Website_tags.filter((f, i) => i !== index);
    setForm({ ...form, Website_tags: newSearcgTags });
  };
  const inputElement = useRef();

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setForm({ ...form, image: URL.createObjectURL(event.target.files[0]) });
    }
  };
  return (
    <Grid item xs={12}>
      <input
        type="file"
        name="filename"
        accept="image/gif, image/jpeg, image/png"
        style={{ display: "none" }}
        ref={inputElement}
        onChange={onImageChange}
      ></input>

      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <IconButton
            onClick={() => {
              inputElement.current.click();
            }}
            aria-label="delete"
          >
            <CreateIcon color="primary" />
          </IconButton>
        }
      >
        <Avatar
          className="profileAvatar"
          alt="Travis Howard"
          src={form.image}
        />
      </Badge>
      <EditableFields label={"Website Name"} value={form.Company_name} />
      <EditableFields label="Website Link" value={form.Website_link} />

      <Typography className="profileItemHeader">Website Tags</Typography>

      <Grid item xs={12} className="paddingStyle">
        <ChipList list={form.Website_tags} onDelete={handleSearchTagDelete} />
      </Grid>

      <Typography className="profileItemHeader">
        Code for your website
      </Typography>

      <TextareaAutosize
        aria-label="minimum height"
        minRows={6}
        fullWidth
        readOnly={true}
        value={
          "<style> \n.tn__elem294629029323892358238 > div > video { \n  position: relative;\n  overflow:hidden;\n}"
        }
        placeholder=""
        className="textarea_business"
      />
    </Grid>
  );
};

export default ReviewForm;
